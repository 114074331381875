import classes from "../SendPage/SendAndReceive.module.css";
export const GeneratedBankAccount = ({
  receiverAccount,
  accountNameDisplay,
  selectedBank,
  anonBankAccountDetails,
}) => {
  return (
    <div className={classes.bankInfoBlock}>
      <h3> Account Details</h3>
      <div className={classes.bankInfoBox}>
        <h4 className={classes.anonSubject}>
          Your Anonymous Bank Account Name:
        </h4>
        <span>{anonBankAccountDetails.account_name}</span>
      </div>

      <div className={classes.bankInfoBox}>
        <h4 className={classes.anonSubject}>
          Your Anonymous Bank Account Number:
        </h4>
        <span>{anonBankAccountDetails.account_number}</span>
      </div>
      <div className={classes.bankInfoBox}>
        <h4 className={classes.anonSubject}>Your Anonymous Bank Name:</h4>
        <span> {anonBankAccountDetails.bank_name}</span>
      </div>
      <div className={classes.warning}>
        <div className={classes.accountdetails}>
          <span>
            Kindly Copy The Above Account Details.
            <br />
            Any amount sent to the above account will be deposited into your
            bank account:
          </span>
          <h5>
            {receiverAccount} ({accountNameDisplay},{selectedBank})
          </h5>
          .
        </div>

        <p className={classes.warningText}>
          ⚠️ Note: The Generated account is only valid for 30 minutes
        </p>
      </div>
    </div>
  );
};
