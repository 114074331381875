import React, { useState, useEffect, useContext, useRef } from "react";
import classes from "./SendAndReceive.module.css";
import axios from "axios";
import Email from "../MinorComponents/Email";
// import PayazaCheckout from "@payaza/web-sdk";
import CryptoJS from "crypto-js";
import { GeneratedBankAccount } from "../MinorComponents/GeneratedBankAccount";
import { SendBankList } from "../SendBankList/SendBankList";
import { NewSendTextBox } from "../MinorComponents/NewSendTextBox";
import SendMethodModal from "../MinorComponents/SendMethodModal";
import { ContactUs } from "../MinorComponents/ContactUs";
import { changeModalState } from "../../Functions/ChangeModalState";
import { minGetAllBanks } from "../../Functions/GetAllBanks";
// import { getAccountName } from "../../Functions/GetAccountForMultiple";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { getAccountName } from "../../Functions/GetAccountName";
import CarouselPage from "../Carousel/Carousel";
import { Switch } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AppStateContext } from "../AppStateContext";
import { debounce } from "lodash.debounce";
import { ThemeContext } from "../../App";
import SuccessfulTransactionImage from "../SuccessfulTransactionImage/SuccessfulTransactionImage";
import Nav from "../../NavigationMobile/NavigationMobile";
const SendAndReceive = () => {
  // const [isSendActive, setisSendActive] = useState(true);
  // const [isReceiveActive, setisReceiveActive] = useState(false);

  const { isSendActive, isReceiveActive, setisReceiveActive, setisSendActive } =
    useContext(AppStateContext);
  const { theme } = useContext(ThemeContext);
  const [bankList, setbankList] = useState(false);
  const [bankListFromApi, setbankListFromApi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBank, setselectedBank] = useState("");
  const [senderName, setsenderName] = useState("");
  const [narration, setnarration] = useState("");
  const [receiverName, setreceiverName] = useState("");
  const [receiverAccount, setreceiverAccount] = useState("");
  const [num10state, setnum10state] = useState("");
  const [amount, setamount] = useState("");
  const [accountNameDisplay, setaccountNameDisplay] = useState(undefined);
  const [sendInitialState, setsendInitialState] = useState(true);
  const [header, setheader] = useState(true);
  const [dropdown, setdropdown] = useState(true);
  const [btnLoading, setbtnLoading] = useState(false);
  const [pricetosEND, setpricetosEND] = useState("");
  const [transaction_reference, settransaction_reference] = useState("");
  const [accountNamePlaceHolder, setaccountNamePlaceHolder] =
    useState("Account Name");
  const [anonName, setanonName] = useState("");
  const [anonBankAccountDetails, setanonBankAccountDetails] = useState([]);
  const [receiveDisableBTN, setreceiveDisableBTN] = useState(true);
  const [sendDisableBTN, setsendDisableBTN] = useState(true);
  const [readOnly, setreadOnly] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [modalReceiveOption, setmodalReceiveOption] = useState("");
  const [receiveModalState, setreceiveModalState] = useState(false);
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [sendModalState, setsendModalState] = useState(false);
  const [payazaModalState, setpayazaModalState] = useState(false);
  const [sucessfulCheckoutImage, setsucessfulCheckoutImage] = useState(false);
  const [feesToDisplay, setfeesToDisplay] = useState("");
  const [inputBackground, setinputBackground] = useState(false);
  const [sendUSDMODAL, setsendUSDMODAL] = useState(false);
  const [dollarAmount, setdollarAmount] = useState("");
  const [dollarEquivalent, setdollarEquivalent] = useState("");
  const [dollarFees, setdollarFees] = useState("");
  const [cardNumber, setcardNumber] = useState("");
  const [expDate, setexpDate] = useState("");
  const [CVV, setCVV] = useState("");
  const [dollarBtn, setdollarBtn] = useState(false);
  const [contatctUs, setcontatctUs] = useState(false);
  const [lessthan10usd, setlessthan10usd] = useState(false);
  const [getUpdates, setgetUpdates] = useState(false);
  const [newsLetterEmail, setnewsLetterEmail] = useState("");
  const [newsLetterstate, setnewsLetterstate] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [scheduledDate, setscheduledDate] = useState(null);
  const [email, setemail] = useState("");
  const [emailIsCorrect, setemailIsCorrect] = useState(false);

  const [clickCount, setclickCount] = useState(0);

  const themes = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          thumb: {
            color: "#A80087", // Customize the color for the active state
          },
          track: {
            backgroundColor: "lightblue", // Customize the background color for the active state
          },
        },
      },
    },
  });

  const countries = [
    { name: "", code: "NG", flag: "🇳🇬" },
    // { name: '', code: 'US', flag: '🇺🇸' },
  ];
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  // This Function tracks the number of values in the account number field, so it chnage the input field placeholder to Getting Account Name ....

  useEffect(() => {
    if (receiverAccount.length < 10) {
      setaccountNamePlaceHolder("Account Name");
    } else if (receiverAccount.length === 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    } else if (receiverAccount.length < 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    }
  }, [receiverAccount, selectedBank]);

  const [checked, setChecked] = useState(false);

  const handleChangeSwitch = () => {
    setChecked(!checked);
  };

  const handleChange = (event) => {
    const selectedCode = event.target.value;
    const selected = countries.find((country) => country.code === selectedCode);
    setSelectedCountry(selected);
  };
  // This Function gets list of Nigerian Banks
  const getAllBanks = () => {
    minGetAllBanks(setbankList, setbankListFromApi);
  };

  // This Function changes the content on the left side of the page and returns display summary
  const changeinitialSendState = () => {
    // setclickCount(clickCount + 1);
    // console.log("its added");

    if (!emailIsCorrect) {
      return;
    }

    if (amount < 10) {
      setsendInitialState(true);
      setinputBackground(false);
      setbtnLoading(false);

      return;
    }
    if (
      accountNameDisplay !== undefined &&
      senderName !== "" &&
      amount !== ""
    ) {
      setsendInitialState(false);
      setheader(false);
      setbtnLoading(true);
      setdropdown(false);
      setreadOnly(true);
    } else if (accountNameDisplay === undefined || accountNameDisplay) {
      setbtnLoading(false);
      setaccountNamePlaceHolder("Bank Name or Account Number  Incorrect");
    }
  };
  //  This handles activation of the form BTN
  useEffect(() => {
    if (
      accountNameDisplay === undefined ||
      anonName === "" ||
      selectedBank === "" ||
      accountNameDisplay === "Name Enquiry failed: Invalid Account" ||
      accountNameDisplay === "Getting Account Name..."
    ) {
      setreceiveDisableBTN(true);
    } else {
      setreceiveDisableBTN(false);
    }
  }, [accountNameDisplay, anonName, selectedBank]);
  //  This handles activation of the form BTN
  useEffect(() => {
    if (
      selectedBank === "" ||
      receiverAccount.length < 10 ||
      amount === "" ||
      narration === "" ||
      senderName === "" ||
      accountNameDisplay === "Name Enquiry failed: Invalid Account" ||
      accountNameDisplay === "Getting Account Name..." ||
      accountNameDisplay === undefined ||
      email === "" ||
      emailIsCorrect === false
    ) {
      setsendDisableBTN(true);
    } else {
      setsendDisableBTN(false);
    }
  }, [
    selectedBank,
    receiverAccount,
    accountNameDisplay,
    amount,
    narration,
    senderName,
    email,
    emailIsCorrect,
  ]);

  useEffect(() => {
    if (num10state === "is 10" && receiverAccount.length < 10) {
      setaccountNameDisplay("Getting Account Name...");
    }
    getAccountName(
      receiverAccount,
      selectedBank,
      setaccountNameDisplay,
      accountNameDisplay,
      num10state,
      setnum10state
    );
  }, [receiverAccount, selectedBank]);

  // This Handles Search
  const filteredBankList = bankListFromApi.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // console.log(startDate);

  // This Fn handles sending of cash
  const sendCash = (e) => {
    if (!emailIsCorrect) {
      return;
    }

    if (clickCount === 0) {
      setclickCount(1);
    } else {
    }
    e.preventDefault();
    setinputBackground(true);

    setsendDisableBTN(true);
    if (amount < 10) {
      setinputBackground(false);
      setsendDisableBTN(false);
      setlessthan10usd(true);

      return;
    } else {
      setlessthan10usd(false);
    }

    // if (selectedCountry.code === 'US') {
    //     axios.post(process.env.REACT_APP_SENDCASH_USD, {
    //         senderName, narration, receiverAccount,
    //         receiverBank: selectedBank, receiverName, amount
    //     }).then((response) => {

    //         setdollarAmount(response.data.response.amount)
    //         setdollarFees(response.data.response.fee)
    //         setdollarEquivalent(response.data.response.nairaEquivalent)
    //         settransaction_reference(response.data.response.transactionReference)
    //         setsendDisableBTN(false)
    //     })
    //     return
    // }
    // if (selectedCountry.code === "US") {
    //   const requestData = {
    //     senderName,
    //     narration,
    //     receiverAccount,
    //     receiverBank: selectedBank,
    //     receiverName,
    //     amount,
    //   };
    //   console.log(requestData);

    //   const request = encryptData(requestData);

    //   axios
    //     .post(process.env.REACT_APP_SENDCASH_USD, {
    //       request,
    //     })
    //     .then((response) => {
    //       // Handle the response
    //       setdollarAmount(response.data.response.amount);
    //       setdollarFees(response.data.response.fee);
    //       setdollarEquivalent(response.data.response.nairaEquivalent);
    //       settransaction_reference(response.data.response.transactionReference);
    //       setsendDisableBTN(false);
    //     });
    //   return;
    // }
    const newDate = startDate.toISOString();
    const formattedDate = newDate.slice(0, -1);
    const requestData = {
      senderName,
      narration,
      receiverAccount,
      receiverBank: selectedBank,
      receiverName: accountNameDisplay,
      amount: parseFloat(amount),
    };
    if (checked) {
      requestData.expectedPayoutDate = formattedDate;
    }

    const request = encryptData(requestData);

    axios
      .post(process.env.REACT_APP_SEND_CASH, {
        request,
      })
      .then((response) => {
        const price = response.data.response.amount;
        const transactionFee = response.data.response.fee;
        const transactionReference =
          response.data.response.transactionReference;
        setscheduledDate(response.data.response.scheduledDate);

        settransaction_reference(transactionReference);
        setpricetosEND(price);
        setfeesToDisplay(transactionFee);
        setsendDisableBTN(false);
      })
      .catch((error) => {
        // Handle the error
      });
  };

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setemailIsCorrect(false);
      return;
    } else {
      setemailIsCorrect(true);
    }
  }, [email]);

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(newsLetterEmail)) {
      return;
    }

    axios
      .post(process.env.REACT_APP_EMAIL_SUBS, {
        email: newsLetterEmail,
      })
      .then((response) => {
        setnewsLetterEmail("");
        setnewsLetterstate(true);
      })
      .catch((error) => {});
  }, [sucessfulCheckoutImage]);
  const newsLetterHandle = () => {
    axios
      .post(process.env.REACT_APP_EMAIL_SUBS, {
        email,
        transactionReference: transaction_reference,
      })
      .then((response) => {
        setnewsLetterEmail("");
        setnewsLetterstate(true);
      })
      .catch((error) => {});
  };

  const openSendModal = () => {
    // e.preventDefault();
    newsLetterHandle();
    if (selectedCountry.code == "NG") {
      setsendModalState(true);
    } else if (selectedCountry.code == "US") {
      setsendUSDMODAL(true);
    }
  };

  const handleDollarPayment = () => {
    setdollarBtn(true);
    const cardNumber1 = cardNumber.replace(/\s/g, ""); // Card Number
    const [firstPart, secondPart] = [expDate.slice(0, 2), expDate.slice(3)];

    const expiryMonth = firstPart; // Card Expiry Month
    const expiryYear = secondPart; // Card Expiry Year
    const securityCode = CVV; // Card CVV
    const stringToEncode = process.env.React_App_PAYAZA_MERCHANT_KEY;
    const base64EncodedString = btoa(stringToEncode);

    const auth = `Payaza ${base64EncodedString}`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", auth);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      service_type: "Account",
      service_payload: {
        request_application: "Payaza",
        application_module: "USER_MODULE",
        application_version: "1.0.0",
        request_class: "UsdCardChargeRequest",
        first_name: "Anon",
        last_name: "Anon",
        email_address: "Anon",
        phone_number: "0812345678",
        amount: `${dollarAmount}`,
        transaction_reference: `${transaction_reference}`,
        currency: "USD",
        description: "Anon",
        card: {
          expiryMonth: expiryMonth,
          expiryYear: expiryYear,
          securityCode: securityCode,
          cardNumber: cardNumber1,
        },
        // "callback_url":"https://redirect.com/"
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://cards-live.78financials.com/card_charge/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);

        if (result.statusOk) {
          // ///Handle Success Response
          // const creq = document.getElementById("creq");
          // creq.value = result.formData;
          // const form = document.getElementById("threedsChallengeRedirectForm");
          // form.setAttribute("action", result.threeDsUrl);
          // form.submit();
          setsucessfulCheckoutImage(true);
          setdollarBtn(false);
        } else {
          // ///Handle Error
          setdollarBtn(false);
          // console.log("Error found", result.debugMessage)
          alert("Payment Failed: " + result.debugMessage);
          // setdollarBtn(false)
        }
      })
      .catch((error) => {
        // console.log("Error", error)
        alert("Exception Error: " + error.debugMessage);
      });

    // ///////////Internal Payment Notification
    window.addEventListener("message", (event) => {
      const response = JSON.parse(event.data);
      // console.log("Payment Notification", response)
      if (response.statusOk === true && response.paymentCompleted === true) {
        // ////Handle payment successful, do business logic
        alert("Payment Successful");
        window.open("https://fast.com/");
      } else {
        // ///Handle Failed payment
        alert("Payment Failed");
      }
    });
  };
  function handleInput(event) {
    const input = event.target;
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
    }
  }

  // const handleNairaPayment = () => {
  //   const payazaCheckout = PayazaCheckout.setup({
  //     merchant_key: process.env.React_App_PAYAZA_MERCHANT_KEY,
  //     connection_mode: "Live", // Live || Test
  //     checkout_amount: pricetosEND,
  //     currency_code: "NGN", // default is NGN. This field is optional
  //     email_address: "cashflow@sentbyanon.com",
  //     first_name: "Anon",
  //     last_name: "Anon",
  //     transaction_reference: transaction_reference,
  //     onClose: function () {
  //       // console.log("Closed")
  //     },
  //     callback: function (callbackResponse) {
  //       // console.log("Response Is Here: ", callbackResponse)
  //       // console.log(callbackResponse)
  //       // console.log(callbackResponse.status)
  //     },
  //   });

  //   // Alternatively, you can set the onClose and callback function as described below
  //   function callback(callbackResponse) {
  //     // console.log("Response Is Here: ", callbackResponse)
  //     // console.log(callbackResponse)
  //     // console.log(callbackResponse.status)
  //     // console.log(callbackResponse.data.message)

  //     if (
  //       callbackResponse.status === 201 &&
  //       callbackResponse.data.message === "Transaction Successful"
  //     ) {
  //       setsucessfulCheckoutImage(true);
  //     }
  //   }

  //   function onClose() {
  //     // console.log("closed")
  //   }

  //   payazaCheckout.setCallback(callback);
  //   payazaCheckout.setOnClose(onClose);

  //   // Display popup
  //   payazaCheckout.showPopup();
  // };

  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };

  const openReceiveModal = (e) => {
    e.preventDefault();
    setreceiveModalState(true);
  };

  const multipleFunctionButtonForReceive = (e) => {
    if (modalReceiveOption === "Naira") {
      generateAnonAccount(e);
      setinitialModalOptions(false);
      setspinner(true);
    } else if (modalReceiveOption === "Dollar") {
    }
  };

  const generateAnonAccount = (e) => {
    e.preventDefault();
    setsendInitialState(false);
    setspinner(true);
    axios
      .post(process.env.REACT_APP_RECEIVE_CASH, {
        request: encryptData({
          bank: selectedBank,
          accountName: anonName,
          accountNumber: receiverAccount,
          receiverName: accountNameDisplay,
        }),
      })
      .then((response) => {
        setanonBankAccountDetails(response.data.response);
        setspinner(false);
      })
      .catch((error) => {
        // Handle the error
      });
  };

  return (
    <section
      className={`${classes.section} ${
        theme === "dark" ? classes.dark : classes.light
      }`}
    >
      {/* <nav className={classes.nav}>
        <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link>
      </nav> */}
      <div className={classes.nav}>
        <Nav />
      </div>

      {sendInitialState && (
        <div className={classes.textBox}>
          <div className={classes.header}>
            <h1>Send Money </h1>
            <h1>Anonymously !</h1>
            <p>surprise that loved one.</p>
          </div>
          {/* <h3>
            Anonymous Payments <br /> Made Easy
          </h3> */}
          <div className={classes.giftimgBox}>
            {theme === "dark" ? (
              <img
                className={classes.giftimg}
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1695538640/sentbyanon/giftmedium_dhxtxw.png"
                alt="anon"
              />
            ) : (
              <img
                className={classes.giftimgwhite}
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1695457048/sentbyanon/whitegift_dtfymt.png"
                alt="anon"
              />
            )}
          </div>

          {/* <div className={classes.leftBottom}>
            <span onClick={() => sethowToUseCarousel(true)}>How to use</span>
            <span onClick={() => setcontatctUs(true)}>Contact Us</span>
            <span onClick={() => setgetUpdates(true)}>Get Updates</span>
          </div> */}
          {/* <span className={classes.copywright}>
            © SentByAnon 2023. All rights reserved.
          </span> */}
        </div>
      )}

      {!sendInitialState && (
        <NewSendTextBox
          sethowToUseCarousel={sethowToUseCarousel}
          setcontatctUs={setcontatctUs}
          setgetUpdates={setgetUpdates}
        />
      )}
      <div className={classes.formBox}>
        <div className={classes.formWrapper}>
          <div>
            {!sucessfulCheckoutImage ? (
              <div className={classes.mainBox}>
                {header && (
                  <div className={classes.sendOrreceive}>
                    <p
                    // onClick={() =>
                    //   changeModalState(
                    //     setSearchTerm,
                    //     setreceiverAccount,
                    //     setaccountNameDisplay,
                    //     setbankList,
                    //     setaccountNamePlaceHolder,
                    //     setisSendActive,
                    //     setisReceiveActive,
                    //     setselectedBank
                    //   )
                    // }
                    // className={`${classes.send}  ${
                    //   isSendActive ? classes.blackBG : classes.whiteBG
                    // }`}
                    >
                      Send
                    </p>
                    {/* <p
                      // onClick={() =>
                      //   changeModalState(
                      //     setSearchTerm,
                      //     setreceiverAccount,
                      //     setaccountNameDisplay,
                      //     setbankList,
                      //     setaccountNamePlaceHolder,
                      //     setisSendActive,
                      //     setisReceiveActive,
                      //     setselectedBank
                      //   )
                      // }
                      className={` ${classes.receive} ${
                        isReceiveActive ? classes.blackBG : classes.whiteBG
                      }`}
                    >
                      Receive
                    </p> */}
                  </div>
                )}
                {!header && (
                  <div className={classes.sendOrreceive}>
                    <p>Summary</p>
                  </div>
                )}
                {isSendActive && (
                  <form onSubmit={sendCash} className={classes.main}>
                    <div className={classes.bank}>
                      <div className={classes.bankBox} onClick={getAllBanks}>
                        <label
                          className={sendInitialState ? "" : classes.boldText}
                        >
                          {selectedBank === "" ? "Bank" : selectedBank}
                        </label>
                        {dropdown && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        )}
                      </div>

                      {sendModalState && (
                        <>
                          <div className={classes.overlay}></div>
                          <div className={classes.modal}>
                            {initialModalOptions && (
                              <>
                                <SendMethodModal
                                  accountNameDisplay={accountNameDisplay}
                                  receiverAccount={receiverAccount}
                                  selectedBank={selectedBank}
                                  setmodalReceiveOption={setmodalReceiveOption}
                                  modalReceiveOption={modalReceiveOption}
                                  setsendModalState={setsendModalState}
                                  pricetosEND={pricetosEND}
                                  transaction_reference={transaction_reference}
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {contatctUs && (
                        <>
                          <div className={classes.overlay}></div>
                          <div className={classes.modal}>
                            {initialModalOptions && (
                              <ContactUs setcontatctUs={setcontatctUs} />
                            )}
                          </div>
                        </>
                      )}

                      <>
                        {/* {sendUSDMODAL &&
                                        <>
                                            <div className={classes.overlay}></div>
                                            <div className={classes.modal}>
                                                <div className={classes.usdModalWrapper}>
                                                    <div>
                                                        <h3>Card Details</h3>
                                                        <div>
                                                            <p>Card Number</p>
                                                            <input
                                                                className={classes.cardNumber}
                                                                type='text'
                                                                maxLength='19'

                                                                value={cardNumber}
                                                                onChange={(e) => setcardNumber(e.target.value)}

                                                                onInput={(e) => {
                                                                    let value = e.target.value.replace(/\s/g, ''); // Remove existing spaces
                                                                    value = value.replace(/(\d{4})/g, '$1 '); // Add a space after every 4 characters
                                                                    e.target.value = value.trim(); // Update the input value

                                                                }}
                                                                placeholder='X X X X  X X X X   X X X X   X X X X'
                                                            />
                                                        </div>

                                                        <div className={classes.monthNcvv}>
                                                            <div>
                                                                <p>Exp. Date</p>
                                                                <input
                                                                    value={expDate}
                                                                    onChange={(e) => setexpDate(e.target.value)}
                                                                    type='text'
                                                                    maxLength='5'
                                                                    onInput={(e) => {
                                                                        let value = e.target.value.replace(/\//g, ''); // Remove existing slashes
                                                                        value = value.replace(/(\d{2})(\d{0,2})/, '$1/$2'); // Add a slash after the first 2 characters
                                                                        e.target.value = value.trim(); // Update the input value
                                                                    }}
                                                                    placeholder='MM/YY'
                                                                />
                                                            </div>

                                                            <div>
                                                                <p>CVV</p>
                                                                <input
                                                                    value={CVV}
                                                                    onChange={(e) => setCVV(e.target.value)}
                                                                    type='number'
                                                                    maxLength='3'
                                                                    onInput={(e) => {
                                                                        if (e.target.value.length > 3) {
                                                                            e.target.value = e.target.value.slice(0, 3);

                                                                        }
                                                                    }}
                                                                    placeholder='X X X'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={classes.usdBtn}>
                                                            <button disabled={dollarBtn} style={{ backgroundColor: dollarBtn ? '#CCCCCC' : 'aaa' }} onClick={handleDollarPayment}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    } */}
                        {/* {getUpdates && <>
                                        <div className={classes.overlay}></div>
                                        <div className={classes.modal}>
                                            {initialModalOptions && <>
                                                <div className={classes.modalClose}>
                                                    <p>.</p>
                                                    <svg onClick={() => setgetUpdates(false)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler 
                                                    icon-tabler-square-rounded-x-filled"
                                                        width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="FBB2FF" fill="FBB2FF"
                                                        stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" fill="currentColor" stroke-width="0" />
                                                    </svg>
                                                </div>




                                                <div className={classes.updateImg} >
                                                    <img src='https://res.cloudinary.com/da3avivzn/image/upload/v1685885815/Group_83_zuchoh.png' alt='' />
                                                </div>
                                                <div>
                                                    <p>Subscribe to get recent updates and new features from us</p>


                                                </div>
                                                <div className={classes.submitForm}>
                                                    <input placeholder='Your Email Address' value={newsLetterEmail} onChange={(e) => setnewsLetterEmail(e.target.value)} />

                                                    <button onClick={newsLetterHandle} >Subscribe</button>


                                                </div>


                                            </>}
                                        </div>
                                    </>} */}
                      </>

                      {bankList && (
                        <SendBankList
                          sendInitialState={sendInitialState}
                          setSearchTerm={setSearchTerm}
                          setbankList={setbankList}
                          setselectedBank={setselectedBank}
                          filteredBankList={filteredBankList}
                          searchTerm={searchTerm}
                        />
                      )}
                    </div>
                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Account Number"
                      value={receiverAccount}
                      // onChange={trackInput}
                      onChange={(e) => setreceiverAccount(e.target.value)}
                      onInput={handleInput}
                      type="number"
                    />
                    <input
                      style={{
                        color:
                          accountNameDisplay ===
                          "Name Enquiry failed: Invalid Account"
                            ? "red"
                            : "",
                      }}
                      // className={sendInitialState ? "" : classes.boldText}
                      readOnly
                      placeholder={
                        accountNameDisplay === undefined ||
                        receiverAccount.length < 10
                          ? accountNamePlaceHolder
                          : accountNameDisplay
                      }
                      value={receiverName}
                      onChange={(e) => setreceiverName(e.target.value)}
                      type="text"
                      className={`${sendInitialState ? "" : classes.boldText} ${
                        accountNameDisplay ===
                        "Name Enquiry failed: Invalid Account"
                          ? classes.redPlaceholder
                          : ""
                      }`}
                    />

                    <div className={classes.currencySelectBox}>
                      <div className={classes.currencySelectBoxWrapper}>
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly={readOnly}
                          required
                          placeholder={
                            selectedCountry.code === "NG"
                              ? "Amount(charged in ₦)"
                              : "Amount(charged in $)"
                          }
                          value={amount}
                          onChange={(e) => setamount(e.target.value)}
                          type="number"
                        />
                        {lessthan10usd && (
                          <p style={{ color: "red" }}>
                            Can't send less than $10
                          </p>
                        )}
                        {!dollarAmount &&
                          !pricetosEND &&
                          // <select
                          //   onChange={handleChange}
                          //   value={selectedCountry.code}
                          //   required
                          // >
                          //   {countries.map((country) => (
                          //     <option key={country.code} value={country.code}>
                          //       {country.flag} {country.name}
                          //     </option>
                          //   ))}
                          // </select>
                          ""}
                      </div>
                    </div>

                    {feesToDisplay === "" ? (
                      ""
                    ) : (
                      <div>
                        Fees
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={`${feesToDisplay}`}
                        />
                      </div>
                    )}

                    {dollarAmount === "" ? (
                      ""
                    ) : (
                      <div>
                        Amount In Dollar
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={` $ ${dollarAmount} `}
                        />
                      </div>
                    )}

                    {dollarEquivalent === "" ? (
                      ""
                    ) : (
                      <div>
                        Recepient gets:
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={` ${dollarEquivalent} in  Naira`}
                        />
                      </div>
                    )}

                    {dollarFees === "" ? (
                      ""
                    ) : (
                      <div>
                        Fees IN USD
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={`$ ${dollarFees}`}
                        />
                      </div>
                    )}
                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Narration"
                      value={narration}
                      onChange={(e) => setnarration(e.target.value)}
                      type="text"
                    />
                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Please provide your email address to receive the transaction receipt."
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      type="email"
                    />

                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Sender Name"
                      value={senderName}
                      onChange={(e) => setsenderName(e.target.value)}
                      type="text"
                    />
                    {scheduledDate === null ? (
                      <ThemeProvider theme={themes}>
                        <div>
                          <Switch
                            checked={checked}
                            onChange={handleChangeSwitch}
                          />
                          <p className={classes.recepient}>
                            {checked
                              ? `${
                                  accountNameDisplay === undefined
                                    ? "Recepient"
                                    : accountNameDisplay
                                } will receive ₦${
                                  amount === "" ? "0" : `${amount}`
                                } by ${moment(startDate).format(
                                  "MMMM Do YYYY"
                                )}`
                              : "Click the Switch Above If You Would Like To Schedule This Payment"}
                          </p>
                        </div>
                      </ThemeProvider>
                    ) : (
                      <div>
                        <p className={classes.recepient}>
                          {`${accountNameDisplay} will receive ₦${amount} on   ${moment(
                            startDate
                          ).format("MMMM Do YYYY")}  `}
                        </p>
                      </div>
                    )}
                    {scheduledDate === null ? (
                      <div>
                        {checked && (
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`   ${
                        sendDisableBTN ? classes.formBtn : classes.formBtnActive
                      }`}
                    >
                      {/* <Link to="./multiple">
                        <button>Make Multiple Payment </button>
                      </Link> */}

                      <button
                        style={{ background: sendDisableBTN ? "#CCCCCC" : "" }}
                        onClick={() =>
                          !btnLoading
                            ? changeinitialSendState(
                                setsendInitialState,
                                setinputBackground,
                                setbtnLoading,
                                setheader,
                                amount,
                                accountNameDisplay,
                                senderName,
                                setdropdown,
                                setreadOnly,
                                setaccountNamePlaceHolder
                              )
                            : openSendModal()
                        }
                        disabled={sendDisableBTN}
                        type={clickCount === 0 ? "submit" : "button"}
                      >
                        {!btnLoading ? "Proceed" : "Proceed to payment"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            ) : (
              <SuccessfulTransactionImage
                transactionRef={transaction_reference}
              />
            )}

            {getUpdates && (
              <>
                <div className={classes.overlay}></div>
                <div className={classes.modal}>
                  {initialModalOptions && (
                    <Email
                      setgetUpdates={setgetUpdates}
                      setnewsLetterstate={setnewsLetterstate}
                      newsLetterEmail={newsLetterEmail}
                      setnewsLetterEmail={setnewsLetterEmail}
                      newsLetterstate={newsLetterstate}
                    />
                  )}
                </div>
              </>
            )}

            {howToUseCarousel && (
              <div>
                <div
                  onClick={() => sethowToUseCarousel(false)}
                  className={classes.overlay}
                ></div>

                <CarouselPage />
              </div>
            )}
            {/* onClick={!btnLoading ? changeinitialSendState : handleSendPayment} */}
            {isReceiveActive && (
              <main className={classes.main}>
                <form>
                  <input
                    required
                    value={anonName}
                    onChange={(e) => setanonName(e.target.value)}
                    placeholder="Your Anon Name"
                    type="text"
                  />
                  {/* {spinner && <div className={classes.center}>
                                    <ClipLoader className={classes.center} color='#ac79b0' />
                                </div>} */}

                  {receiveModalState && (
                    <>
                      <div className={classes.overlay}></div>
                      <div className={classes.modal}>
                        {initialModalOptions && (
                          <>
                            <h3>Receive Method</h3>
                            <div className={classes.checkBox}>
                              <inpu />
                            </div>
                            <div
                              onClick={() => setmodalReceiveOption("Naira")}
                              className={classes.checkBox}
                            >
                              <input
                                type="radio"
                                id="naira"
                                name="receiveMethod"
                                value="naira"
                              />
                              <label htmlFor="naira">Naira</label>
                            </div>
                            <div className={classes.checkBox}>
                              <inpu />
                              <label>Crypto(Coming Soon)</label>
                            </div>
                            <div>
                              <button
                                onClick={multipleFunctionButtonForReceive}
                              >
                                Continue
                              </button>
                            </div>
                          </>
                        )}

                        {spinner && (
                          <div className={classes.center}>
                            <ClipLoader
                              className={classes.center}
                              color="#ac79b0"
                            />
                          </div>
                        )}
                        {!initialModalOptions && anonBankAccountDetails && (
                          <GeneratedBankAccount
                            receiverAccount={receiverAccount}
                            anonBankAccountDetails={anonBankAccountDetails}
                            accountNameDisplay={accountNameDisplay}
                            selectedBank={selectedBank}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {contatctUs && (
                    <>
                      <div className={classes.overlay}></div>
                      <div className={classes.modal}>
                        {initialModalOptions && (
                          <ContactUs setcontatctUs={setcontatctUs} />
                        )}
                      </div>
                    </>
                  )}

                  <div
                    className={`   ${
                      receiveDisableBTN
                        ? classes.formBtn
                        : classes.formBtnActive
                    }`}
                  >
                    <button
                      onClick={openReceiveModal}
                      disabled={receiveDisableBTN}
                      style={{
                        backgroundColor: receiveDisableBTN ? "#CCCCCC" : "",
                      }}
                      type="submit"
                    >
                      Choose Receive Method
                    </button>
                  </div>
                  <div className={classes.bank}>
                    <div className={classes.bankBox} onClick={getAllBanks}>
                      <label
                        className={sendInitialState ? "" : classes.boldText}
                      >
                        {selectedBank === "" ? "Bank" : selectedBank}
                      </label>
                      {dropdown && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                    {bankList && (
                      <SendBankList
                        sendInitialState={sendInitialState}
                        setSearchTerm={setSearchTerm}
                        setbankList={setbankList}
                        setselectedBank={setselectedBank}
                        filteredBankList={filteredBankList}
                        searchTerm={searchTerm}
                      />
                    )}
                  </div>
                  <input
                    readOnly={readOnly}
                    required
                    value={receiverAccount}
                    onChange={(e) => setreceiverAccount(e.target.value)}
                    placeholder="Account Number"
                    type="number"
                  />
                  <input
                    required
                    readOnly
                    className={sendInitialState ? "" : classes.boldText}
                    placeholder={
                      accountNameDisplay === undefined
                        ? accountNamePlaceHolder
                        : accountNameDisplay
                    }
                    value={receiverName}
                    onChange={(e) => setreceiverName(e.target.value)}
                    type="text"
                  />
                </form>
              </main>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendAndReceive;
