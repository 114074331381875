import React from "react";
import Nav from "../../NavigationMobile/NavigationMobile";
import classes from "./AboutUsPage.module.css";
const AboutUsPage = () => {
  return (
    <section className={classes.sections}>
      <Nav />
      <main className={classes.main}>
        <div className={classes.header}>
          <h3>About Us</h3>
        </div>
        <div className={classes.center}>
          <div className={classes.doubleBoxRow}>
            <div className={classes.box}>
              <div className={classes.textBox}>
                <h6>
                  Welcome to SentbyAnon - Your Trusted Anonymous Money Transfer
                  Platform!
                </h6>
                <p>
                  We believe in the power of financial freedom and the right to
                  privacy. We have created a secure and user-friendly fintech
                  platform that allows you to send and receive money
                  anonymously, offering various currency options. Whether you
                  need to send funds in Dollars, Euros, Bitcoin, or USDT to
                  Naira, we've got you covered.
                </p>
              </div>
            </div>
            <div className={classes.box}>
              <img
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1688393634/sentbyanon/smartphone-with-online-payment-hand-touch-banking-online-bill-payment-approved-concept-button-credit-card-network-connection-icon-business-technology-virtual-screen-background_1_tzt4ey.png"
                alt=""
              />
            </div>
          </div>
          <div className={`${classes.doubleBoxRow} ${classes.flip}`}>
            <div className={classes.box}>
              <img
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1688395730/sentbyanon/Laptop_1_kmnl7w.jpg"
                alt=""
              />
            </div>
            <div className={classes.box}>
              <div className={classes.textBox}>
                {/* <h6>
                  Welcome to SentbyAnon - Your Trusted Anonymous Money Transfer
                  Platform!
                </h6> */}
                <p>
                  Our platform is designed to provide a seamless and hassle-free
                  experience for individuals and businesses alike. Privacy is a
                  fundamental concern for many users, so we have developed
                  advanced security measures to ensure your transactions remain
                  confidential and secure. With SentbyAnon, you can enjoy the
                  benefits of anonymous money transfers without compromising
                  your privacy. Here's why you can trust SentbyAnon with your
                  financial needs:
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.benefits}>
          <div className={classes.row}>
            <div className={classes.eachBox}>
              <p>
                <h6>Anonymous Transactions:</h6> We respect your privacy and
                believe in keeping your personal information secure. Using
                SentbyAnon, you can send and receive money without revealing
                your identity or personal details.!
              </p>
            </div>
            <div className={classes.eachBox}>
              <p>
                <h6> Multiple Currency Options:</h6>
                We support various currencies to cater to your diverse financial
                needs. Whether you want to send money in Dollars, Euros,
                Bitcoin, or USDT, we provide a convenient platform for seamless
                currency conversions.
              </p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.eachBox}>
              <p>
                <h6>Quick and Efficient:</h6>Time is valuable, and we understand
                the importance of fast transactions. With our platform, you can
                expect swift transfers that promptly ensure your money reaches
                its intended recipient.
              </p>
            </div>
            <div className={classes.eachBox}>
              <p>
                <h6> Competitive Exchange Rates:</h6>
                We offer competitive exchange rates to ensure you get the most
                value for your money. Our rates are transparent and aim to
                provide fair and reliable service.
              </p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.eachBox}>
              <p>
                <h6>Robust Security Measures:</h6> We prioritize the security of
                your funds and personal information. Our platform utilizes
                advanced encryption techniques and security protocols to
                safeguard your transactions and data from unauthorized access.
              </p>
            </div>
            <div className={classes.eachBox}>
              <p>
                <h6> User-Friendly Interface:</h6>
                We have designed our platform with user experience in mind. Our
                intuitive interface makes it easy for anyone, regardless of
                technical expertise, to navigate and utilize our services
                effortlessly.
              </p>
            </div>
          </div>
        </div>
        <p className={classes.note}>
          At <span>SentbyAnon</span>, we aim to revolutionize how you transfer
          and receive money while prioritizing your privacy and security. We are
          committed to providing a reliable and convenient platform that
          empowers you to manage your finances with peace of mind. Experience
          the benefits of anonymous money transfers and seamless currency
          conversions. Embrace financial freedom with <span>SentbyAnon</span> -
          your trusted partner in anonymous money transfers!
        </p>
        <div className={classes.copywright}>
          <span>© SentbyAnon 2023. All rights reserved.</span>
        </div>
      </main>
    </section>
  );
};

export default AboutUsPage;
