import classes from "../SendPage/SendAndReceive.module.css";

import axios from "axios";
const Email = ({
  setgetUpdates,
  newsLetterEmail,
  setnewsLetterEmail,
  newsLetterstate,
  setnewsLetterstate,
}) => {
  const newsLetterHandle = (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(newsLetterEmail)) {
      return;
    }

    axios
      .post(process.env.REACT_APP_EMAIL_SUBS, {
        email: newsLetterEmail,
      })
      .then((response) => {
        setnewsLetterEmail("");
        setnewsLetterstate((prev) => !prev);
      })
      .catch((error) => {});
  };
  return (
    <form onSubmit={newsLetterHandle}>
      <div className={classes.modalClose}>
        <p>.</p>
        <svg
          onClick={() => setgetUpdates((prev) => !prev)}
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler 
                                  icon-tabler-square-rounded-x-filled"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="FBB2FF"
          fill="FBB2FF"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
            fill="currentColor"
            stroke-width="0"
          />
        </svg>
      </div>

      <div className={classes.updateImg}>
        <img
          src="https://res.cloudinary.com/da3avivzn/image/upload/v1685885815/Group_83_zuchoh.png"
          alt=""
        />
      </div>
      <div>
        <p>Subscribe to get recent updates and new features from us</p>
      </div>
      <div className={classes.submitForm}>
        <input
          required
          placeholder="Your Email Address"
          value={newsLetterEmail}
          type="email"
          onChange={(e) => setnewsLetterEmail(e.target.value)}
        />

        <button type="submit">
          {!newsLetterstate ? "Subscribe" : "Welcome, Anon 😶‍🌫️"}
        </button>
      </div>
    </form>
  );
};
export default Email;

//onClick={() => setgetUpdates((prev) => !prev)}
