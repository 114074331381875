// import classes from "./SendBankListForMultiple.module.css";
import classes from "../MultiplePage/MultiplePage.module.css";
export const SendBankListForMultiple = ({
  sendInitialState,
  searchTerm,
  setbankList,
  setselectedBank,
  filteredBankList,
  setSearchTerm,
}) => {
  return (
    <div
      style={{
        display: !sendInitialState ? "none" : "block",
      }}
      className={classes.bankLists}
    >
      <input
        className={classes.search}
        type="text"
        placeholder="Search for a bank"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredBankList.length > 0 ? (
        <div
          onClick={() => setbankList(false)}
          className={classes.searchedList}
        >
          {filteredBankList.map((item, index) => (
            <li onClick={() => setselectedBank(item)} key={index}>
              {item}
            </li>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};
