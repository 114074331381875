import React, { useState } from "react";
import classes from "./HowToUsePage.module.css";
import { Link } from "react-router-dom";
import { ContactUs } from "../MinorComponents/ContactUs";
import CarouselPage from "../Carousel/Carousel";
import ReactPaginate from "react-paginate";
import Nav from "../../NavigationMobile/NavigationMobile";

const HowToUsePage = () => {
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [contactUs, setcontactUs] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  const [imageContent, setimageContent] = useState("How To Send");
  const [restImage, setrestImage] = useState(false);

  return (
    <section className={classes.section}>
      <Nav />
      {contactUs && (
        <>
          <div className={classes.overlay}></div>
          <div className={classes.modal}>
            {initialModalOptions && <ContactUs setcontatctUs={setcontactUs} />}
          </div>
        </>
      )}{" "}
      {howToUseCarousel && (
        <div>
          <div
            onClick={() => sethowToUseCarousel(false)}
            className={classes.overlay}
          ></div>

          <CarouselPage />
        </div>
      )}
      <main className={classes.main}>
        <div className={classes.header}>
          <h4>{imageContent}</h4>
        </div>
        {imageContent === "How To Send" && (
          <>
            <div className={` ${classes.imageBox}   ${classes.display1}`}>
              <svg
                onClick={() => setrestImage(false)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-left"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                <path d="M8 12l4 4" />
                <path d="M8 12h8" />
                <path d="M12 8l-4 4" />
              </svg>
              {!restImage && (
                <>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324938/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.07_AM_puzpk2.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324960/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_n7wgag.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>

                  <div className={classes.img}>
                    <img
                      src="                https://res.cloudinary.com/da3avivzn/image/upload/v1686324941/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_1_bcilpt.jpg
                    "
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}

              {restImage && (
                <>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324945/sentbyanon/WhatsApp_Image_2023-06-06_at_2.06.51_PM_qirsti.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324947/sentbyanon/WhatsApp_Image_2023-06-06_at_2.07.00_PM_ugdjab.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324947/sentbyanon/WhatsApp_Image_2023-06-06_at_2.07.00_PM_ugdjab.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}
              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
            <div className={` ${classes.imageBox}   ${classes.display2}`}>
              <svg
                onClick={() => setrestImage(false)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-left"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                <path d="M8 12l4 4" />
                <path d="M8 12h8" />
                <path d="M12 8l-4 4" />
              </svg>

              <>
                <div className={classes.img}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324938/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.07_AM_puzpk2.jpg"
                    alt="howtousesentbyanon"
                  />
                </div>
                <div className={classes.img}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324960/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_n7wgag.jpg"
                    alt="howtousesentbyanon"
                  />
                </div>

                <div className={classes.img}>
                  <img
                    src="                https://res.cloudinary.com/da3avivzn/image/upload/v1686324941/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_1_bcilpt.jpg
        "
                    alt="howtousesentbyanon"
                  />
                </div>
              </>

              <>
                <div className={classes.img}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324945/sentbyanon/WhatsApp_Image_2023-06-06_at_2.06.51_PM_qirsti.jpg"
                    alt="howtousesentbyanon"
                  />
                </div>
                <div className={classes.img}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324947/sentbyanon/WhatsApp_Image_2023-06-06_at_2.07.00_PM_ugdjab.jpg"
                    alt="howtousesentbyanon"
                  />
                </div>
                {/* <div className={classes.img}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324947/sentbyanon/WhatsApp_Image_2023-06-06_at_2.07.00_PM_ugdjab.jpg"
                    alt="howtousesentbyanon"
                  />
                </div> */}
              </>

              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
          </>
        )}
        {imageContent === "How To Receive" && (
          <>
            <div className={` ${classes.imageBox}   ${classes.display1}`}>
              <div className={classes.imageBox}>
                <svg
                  onClick={() => setrestImage(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-circle-arrow-left"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke={restImage ? "#A800B7" : ""}
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                  <path d="M8 12l4 4" />
                  <path d="M8 12h8" />
                  <path d="M12 8l-4 4" />
                </svg>
              </div>
              {!restImage && (
                <>
                  <div className={classes.img1}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326221/sentbyanon/www.sentbyanon.com_3_wmtier.png"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img1}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686347898/sentbyanon/www.sentbyanon.com_7_ta4zhh.png"
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}
              {restImage && (
                <>
                  <div className={classes.img1}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326020/sentbyanon/www.sentbyanon.com_1_nz1zly.png"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img1}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326015/sentbyanon/www.sentbyanon.com_2_gquke4.png"
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}
              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
            <div className={` ${classes.imageBox}   ${classes.display2}`}>
              <div className={classes.imageBox}>
                <svg
                  onClick={() => setrestImage(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-circle-arrow-left"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke={restImage ? "#A800B7" : ""}
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                  <path d="M8 12l4 4" />
                  <path d="M8 12h8" />
                  <path d="M12 8l-4 4" />
                </svg>
              </div>

              <>
                <div className={classes.img1}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326221/sentbyanon/www.sentbyanon.com_3_wmtier.png"
                    alt="howtousesentbyanon"
                  />
                </div>
                <div className={classes.img1}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686347898/sentbyanon/www.sentbyanon.com_7_ta4zhh.png"
                    alt="howtousesentbyanon"
                  />
                </div>
              </>

              <>
                <div className={classes.img1}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326020/sentbyanon/www.sentbyanon.com_1_nz1zly.png"
                    alt="howtousesentbyanon"
                  />
                </div>
                <div className={classes.img1}>
                  <img
                    src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326015/sentbyanon/www.sentbyanon.com_2_gquke4.png"
                    alt="howtousesentbyanon"
                  />
                </div>
              </>

              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
          </>
        )}
        {imageContent === "How To Schedule" && (
          <>
            <div className={` ${classes.imageBox}   ${classes.display1}`}>
              <svg
                onClick={() => setrestImage(false)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-left"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                <path d="M8 12l4 4" />
                <path d="M8 12h8" />
                <path d="M12 8l-4 4" />
              </svg>
              {!restImage && (
                <>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.30_AM_qjkqte.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.31_AM_guoty9.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>

                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391762/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.57_AM_pgkegg.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}

              {restImage && (
                <>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391766/sentbyanon/WhatsApp_Image_2023-07-03_at_2.39.50_AM_id0zwk.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391769/sentbyanon/WhatsApp_Image_2023-07-03_at_2.39.58_AM_nwz9mt.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                  <div className={classes.img}>
                    <img
                      src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.40.08_AM_vmfreb.jpg"
                      alt="howtousesentbyanon"
                    />
                  </div>
                </>
              )}
              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
            <div className={` ${classes.imageBox}   ${classes.display2}`}>
              <svg
                onClick={() => setrestImage(false)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-left"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 21a9 9 0 1 0 0 -18a9 9 0 0 0 0 18" />
                <path d="M8 12l4 4" />
                <path d="M8 12h8" />
                <path d="M12 8l-4 4" />
              </svg>

              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.30_AM_qjkqte.jpg"
                  alt="howtousesentbyanon"
                />
              </div>
              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.31_AM_guoty9.jpg"
                  alt="howtousesentbyanon"
                />
              </div>

              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391762/sentbyanon/WhatsApp_Image_2023-07-03_at_2.38.57_AM_pgkegg.jpg"
                  alt="howtousesentbyanon"
                />
              </div>

              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391766/sentbyanon/WhatsApp_Image_2023-07-03_at_2.39.50_AM_id0zwk.jpg"
                  alt="howtousesentbyanon"
                />
              </div>
              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391769/sentbyanon/WhatsApp_Image_2023-07-03_at_2.39.58_AM_nwz9mt.jpg"
                  alt="howtousesentbyanon"
                />
              </div>
              <div className={classes.img}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1688391768/sentbyanon/WhatsApp_Image_2023-07-03_at_2.40.08_AM_vmfreb.jpg"
                  alt="howtousesentbyanon"
                />
              </div>

              <svg
                onClick={() => setrestImage(true)}
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-circle-arrow-right"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={!restImage ? "#A800B7" : ""}
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                <path d="M16 12l-4 -4" />
                <path d="M16 12h-8" />
                <path d="M12 16l4 -4" />
              </svg>
            </div>
          </>
        )}

        <div className={classes.controller}>
          <div className={classes.controllerBox}>
            <div onClick={() => setimageContent("How To Send")}>
              <h6>How To Send</h6>
            </div>
            <div onClick={() => setimageContent("How To Receive")}>
              <h6>How To Receive</h6>
            </div>
            <div>
              <h6>Multiple Payment</h6>
            </div>
            <div onClick={() => setimageContent("How To Schedule")}>
              <h6>How To Schedule</h6>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default HowToUsePage;
