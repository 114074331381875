import classes from "../SendPage/SendAndReceive.module.css";
export const NewSendTextBox = ({
  sethowToUseCarousel,
  setcontatctUs,
  setgetUpdates,
}) => {
  return (
    <div className={classes.newSendTextBox}>
      <nav>
        <img
          src="https://res.cloudinary.com/da3avivzn/image/upload/v1684328321/sentbyanon/SentbyAnon_1_byqsqd.png"
          alt=""
        />
      </nav>
      <div>
        <h1>Important Note</h1>
        <ul>
          <li>
            Note Please confirm the recipient, as we are not responsible for the
            recovery of funds if sent to the wrong destination.
          </li>
          <li>
            Our system does not hold funds sent and can only be recovered from
            the bank provider if the recipient does not receive value.
          </li>
          <li>
            Kindly keep your transaction reference so we can track your
            transactions.
          </li>
        </ul>
      </div>
      <div className={classes.newBottomBox}>
        <div className={classes.leftBottom}>
          <span onClick={() => sethowToUseCarousel((prev) => !prev)}>
            How to use
          </span>
          <span onClick={() => setcontatctUs((prev) => !prev)}>Contact Us</span>
          <span onClick={() => setgetUpdates((prev) => !prev)}>
            Get Updates
          </span>
        </div>
        <span className={classes.copywright}>
          © SentByAnon 2023. All rights reserved.
        </span>
      </div>
    </div>
  );
};
