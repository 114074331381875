import React, { useContext, useEffect, useState } from "react";
import classes from "./GetLink.module.css";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";

import { ScaleLoader } from "react-spinners";
import { ShareSocial } from "react-share-social";
import Nav from "../../NavigationMobile/NavigationMobile";
import { ThemeContext } from "../../App";
const GetLink = () => {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [isTokenAvailable, setisTokenAvailable] = useState(false);
  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [linkToDisplay, setlinkToDisplay] = useState("");
  const [tokenInput, settokenInput] = useState("");
  const [disabledBTN, setdisabledBTN] = useState(true);
  const [isLinkCopied, setisLinkCopied] = useState(false);
  const [emailisNotCorrect, setemailisNotCorrect] = useState(true);
  const [displayLoader, setdisplayLoader] = useState(false);
  const [incorrectToken, setincorrectToken] = useState(false);
  const { theme } = useContext(ThemeContext);
  const [linkPlaceholder, setlinkPlaceholder] = useState("");

  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };
  const style = {
    root: {
      // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      borderRadius: 3,
      border: 0,
      // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      backgroundColor: "#FEF5FF",
    },
    copyContainer: {
      border: "1px solid blue",
      background: "rgb(0,0,0,0.7)",
      display: "none",
    },
    title: {
      fontStyle: "italic",
      fontSize: "24px",
      display: "none",
      color: "red",
    },
  };

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email) || name.length < 3) {
      setemailisNotCorrect(true);
      setdisabledBTN(true);
    } else {
      setemailisNotCorrect(false);
      setdisabledBTN(false);
    }

    // if (emailisNotCorrect && name == "") {
    //   setdisabledBTN(true);
    //   return;
    // } else {
    //   setdisabledBTN(false);
    // }
  }, [email, name]);

  const getTokenHandler = (e) => {
    e.preventDefault();
    setdisabledBTN(true);
    setdisplayLoader(true);
    const requestData = {
      email,
      name,
    };
    const request = encryptData(requestData);
    axios
      .post(process.env.REACT_APP_SEND_CASH, { request })
      .then((response) => {
        const data = response.data;

        if (data && data.response.linkReference != "") {
          setisTokenAvailable(true);
          setlinkToDisplay(data.response.linkReference);
          setdisabledBTN(false);
          setdisplayLoader(false);
        }
      })
      .catch((error) => {});
  };
  const verifyTokenHandler = (e) => {
    e.preventDefault();
    if (tokenInput.length < 10) {
      return;
    }
    setdisabledBTN(true);
    setdisplayLoader(true);

    // const newlink = linkToDisplay.slice(22, 43);

    const requestData = {
      linkRef: linkToDisplay,
      code: tokenInput,
    };
    const request = encryptData(requestData);
    axios
      .post(process.env.REACT_APP_SEND_CASH, { request })
      .then((response) => {
        const data = response.data;
        if (data.response.email) {
          //   setisTokenAvailable(true);
          //   setlinkToDisplay(data.response.link);
          setlinkPlaceholder(response.data.response.link);
          setdisabledBTN(false);
          setisEmailVerified(true);
          setincorrectToken(false);
        } else {
          setincorrectToken(true);
          setdisplayLoader(false);
          setdisabledBTN(false);
        }
      })
      .catch((error) => {});
  };
  function copyPaymentLink() {
    const url = linkPlaceholder;

    // Create a temporary input element
    const input = document.createElement("input");
    input.value = url;

    // Append the input element to the DOM
    document.body.appendChild(input);

    // Select the input's text
    input.select();

    // Copy the text to the clipboard
    document.execCommand("copy");

    // Remove the input element from the DOM
    document.body.removeChild(input);

    // Optional: Provide visual feedback or show a success message
    setisLinkCopied(true);
  }
  return (
    <section
      className={`${classes.section} ${
        theme === "dark" ? classes.dark : classes.light
      }`}
    >
      <nav className={classes.nav}>
        {/* <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link> */}
        <Nav />
      </nav>
      <main className={classes.main}>
        <div className={classes.leftSide}>
          {!isEmailVerified ? (
            <div className={classes.header}>
              <h1>Create&nbsp;Anonymous</h1>
              <h1> Surprise Link</h1>
            </div>
          ) : (
            <div className={classes.header}>
              <h1>Hi&nbsp;there! Your&nbsp;Link&nbsp;has&nbsp;been&nbsp;</h1>
              <h1>Generated&nbsp;Succesfully</h1>
            </div>
          )}

          {!isEmailVerified ? (
            <h4>
              Get money surprises from friends, co-workers, <br /> and Fans
              without knowing the sender.
            </h4>
          ) : (
            <h4>You can now share your link to your friends, family & fans.</h4>
          )}

          <form onSubmit={verifyTokenHandler} className={classes.linkForm}>
            {!isEmailVerified && (
              <input
                value={name}
                onChange={(e) => setname(e.target.value)}
                placeholder="Enter Your Link Name"
              />
            )}
            {!isTokenAvailable && !isEmailVerified && (
              <input
                value={email}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Enter Your Email Address"
              />
            )}
            {isTokenAvailable && !isEmailVerified && (
              <input
                className={classes.fromLeft}
                value={tokenInput}
                onChange={(e) => settokenInput(e.target.value)}
                placeholder="*****"
              />
            )}
            {isTokenAvailable && !isEmailVerified && (
              <p>Enter Token Sent to provided email address</p>
            )}
            <br />
            {!isTokenAvailable && !isEmailVerified && (
              <div className={classes.emailInput}>
                <button
                  disabled={disabledBTN}
                  style={{ backgroundColor: disabledBTN ? "#ccc" : "" }}
                  onClick={getTokenHandler}
                >
                  Verify Email
                </button>
                <div>{displayLoader && <ScaleLoader color="#a800b7" />}</div>
              </div>
            )}
            {incorrectToken && <p>Incorrrect Token</p>}

            {isTokenAvailable && !isEmailVerified && (
              <div>
                <button
                  style={{ backgroundColor: disabledBTN ? "#ccc" : "" }}
                  type="submit"
                  className={classes.generateBTN}
                >
                  Generate Link
                </button>
                <div>{displayLoader && <ScaleLoader color="#a800b7" />}</div>
              </div>
            )}
            {isEmailVerified && (
              <div className={classes.linkBox}>
                <input readOnly placeholder={linkPlaceholder} />
                <svg
                  onClick={copyPaymentLink}
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-copy"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#a800b7"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                </svg>
              </div>
            )}
            {isLinkCopied && <p>Copied</p>}

            {isEmailVerified && (
              <div className={classes.btnWidth}>
                {/* <div className={classes.textShare}>
                  <ShareSocial
                    style={style}
                    socialTypes={["whatsapp", "twitter"]}
                    title={`You can send me money anonymously on sentbyanon through this link ${
                      "https://sentbyanon.com/" + linkToDisplay + "/" + name
                    }`}
                  />
                </div> */}

                {/* <button className={classes.whatsapp}> Share On Twitter</button> */}
                <Link to="/dashboard">
                  <button className={classes.dashboard}>View Dashboard</button>
                </Link>
              </div>
            )}
          </form>
        </div>
        <div className={classes.rightBox}>
          <div className={classes.imgBox}>
            {!isEmailVerified ? (
              <div className={classes.imgBox}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1691093083/sentbyanon/Group_263_6_n58rju.png"
                  alt=""
                />
              </div>
            ) : (
              <div className={classes.imgBox}>
                <img
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1691092883/sentbyanon/Group_264_4_j5xi0p.png"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </main>
    </section>
  );
};

export default GetLink;
