import React, { useState, useEffect, useContext } from "react";
import classes from "./PoundsPayment.module.css";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import Email from "../MinorComponents/Email";
// import PayazaCheckout from "@payaza/web-sdk";

import CryptoJS from "crypto-js";
import { SendBankList } from "../SendBankList/SendBankList";
import { NewSendTextBox } from "../MinorComponents/NewSendTextBox";
import SendMethodModal from "../MinorComponents/SendMethodModal";
import { ContactUs } from "../MinorComponents/ContactUs";

import { minGetAllBanks } from "../../Functions/GetAllBanks";
// import { getAccountName } from "../../Functions/GetAccountForMultiple";

import { Link } from "react-router-dom";
import { getAccountName } from "../../Functions/GetAccountName";
import CarouselPage from "../Carousel/Carousel";

import { createTheme } from "@mui/material/styles";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
import { AppStateContext } from "../AppStateContext";
import { debounce } from "lodash.debounce";

import SuccessfulTransactionImage from "../SuccessfulTransactionImage/SuccessfulTransactionImage";

const PoundsPayment = () => {
  // const [isSendActive, setisSendActive] = useState(true);
  // const [isReceiveActive, setisReceiveActive] = useState(false);
  const [bankList, setbankList] = useState(false);
  const { isSendActive, isReceiveActive, setisReceiveActive, setisSendActive } =
    useContext(AppStateContext);
  const [redBorder, setredBorder] = useState(true);
  const [bankListFromApi, setbankListFromApi] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBank, setselectedBank] = useState("");
  const [senderName, setsenderName] = useState("");
  const [narration, setnarration] = useState("");
  const [receiverName, setreceiverName] = useState("");
  const [receiverAccount, setreceiverAccount] = useState("");
  const [num10state, setnum10state] = useState("");
  const [amount, setamount] = useState("");
  const [accountNameDisplay, setaccountNameDisplay] = useState(undefined);
  const [sendInitialState, setsendInitialState] = useState(true);
  const [header, setheader] = useState(true);
  const [dropdown, setdropdown] = useState(true);
  const [btnLoading, setbtnLoading] = useState(false);
  const [pricetosEND, setpricetosEND] = useState("");
  const [transaction_reference, settransaction_reference] = useState("");
  const [accountNamePlaceHolder, setaccountNamePlaceHolder] =
    useState("Account Name");
  const [anonName, setanonName] = useState("");
  const [anonBankAccountDetails, setanonBankAccountDetails] = useState([]);
  const [receiveDisableBTN, setreceiveDisableBTN] = useState(true);
  const [sendDisableBTN, setsendDisableBTN] = useState(true);
  const [readOnly, setreadOnly] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [modalReceiveOption, setmodalReceiveOption] = useState("");
  const [receiveModalState, setreceiveModalState] = useState(false);
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [sendModalState, setsendModalState] = useState(false);
  const [payazaModalState, setpayazaModalState] = useState(false);
  const [sucessfulCheckoutImage, setsucessfulCheckoutImage] = useState(false);
  const [feesToDisplay, setfeesToDisplay] = useState("");
  const [inputBackground, setinputBackground] = useState(false);
  const [sendUSDMODAL, setsendUSDMODAL] = useState(false);

  const [dollarEquivalent, setdollarEquivalent] = useState("");
  const [dollarFees, setdollarFees] = useState("");
  const [cardNumber, setcardNumber] = useState("");
  const [expDate, setexpDate] = useState("");
  const [CVV, setCVV] = useState("");
  const [dollarBtn, setdollarBtn] = useState(false);
  const [contatctUs, setcontatctUs] = useState(false);
  const [lessthan10usd, setlessthan10usd] = useState(false);
  const [getUpdates, setgetUpdates] = useState(false);
  const [newsLetterEmail, setnewsLetterEmail] = useState("");
  const [newsLetterstate, setnewsLetterstate] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [scheduledDate, setscheduledDate] = useState(null);
  const [fetchedRate, setfetchedRate] = useState(850);
  const [nairaAmount, setnairaAmount] = useState("");
  const [USDplaceholder, setUSDplaceholder] = useState(0);
  const [stripeUrl, setstripeUrl] = useState("");
  const [email, setemail] = useState("");
  const [clickCount, setclickCount] = useState(0);
  const [poundsRange, setpoundsRange] = useState(false);

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          thumb: {
            color: "#A80087", // Customize the color for the active state
          },
          track: {
            backgroundColor: "lightblue", // Customize the background color for the active state
          },
        },
      },
    },
  });

  const countries = [
    { name: "", code: "NG", flag: "🇳🇬" },
    // { name: '', code: 'US', flag: '🇺🇸' },
  ];
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  // This Function tracks the number of values in the account number field, so it chnage the input field placeholder to Getting Account Name ....

  useEffect(() => {
    if (receiverAccount.length < 10) {
      setaccountNamePlaceHolder("Account Name");
    } else if (receiverAccount.length === 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    } else if (receiverAccount.length < 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    }
  }, [receiverAccount, selectedBank]);

  const [checked, setChecked] = useState(false);

  const handleChangeSwitch = () => {
    setChecked(!checked);
  };

  // This Function gets list of Nigerian Banks
  const getAllBanks = () => {
    minGetAllBanks(setbankList, setbankListFromApi);
  };

  // This Function changes the content on the left side of the page and returns display summary
  const changeinitialSendState = () => {
    if (accountNameDisplay !== undefined && senderName !== "") {
      setsendInitialState(false);
      setheader(false);
      setbtnLoading(true);
      setdropdown(false);
      setreadOnly(true);
    } else if (accountNameDisplay === undefined || accountNameDisplay) {
      setbtnLoading(false);
      setaccountNamePlaceHolder("Bank Name or Account Number  Incorrect");
    }
  };

  //  This handles activation of the form BTN
  useEffect(() => {
    if (
      selectedBank === "" ||
      receiverAccount.length < 10 ||
      nairaAmount < 8500 ||
      nairaAmount === "" ||
      nairaAmount >= 850020 ||
      narration === "" ||
      USDplaceholder === 0 ||
      USDplaceholder === "" ||
      senderName === "" ||
      accountNameDisplay === "Name Enquiry failed: Invalid Account" ||
      accountNameDisplay === "Getting Account Name..." ||
      accountNameDisplay === undefined
    ) {
      setsendDisableBTN(true);
    } else {
      setsendDisableBTN(false);
    }
  }, [
    selectedBank,
    receiverAccount,
    accountNameDisplay,
    amount,
    narration,
    senderName,
    USDplaceholder,
    nairaAmount,
  ]);

  useEffect(() => {
    if (num10state === "is 10" && receiverAccount.length < 10) {
      setaccountNameDisplay("Getting Account Name...");
    }
    getAccountName(
      receiverAccount,
      selectedBank,
      setaccountNameDisplay,
      accountNameDisplay,
      num10state,
      setnum10state
    );
  }, [receiverAccount, selectedBank]);

  // This Handles Search
  const filteredBankList = bankListFromApi.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // console.log(startDate);

  // This Fn handles sending of cash
  const sendCash = (e) => {
    if (clickCount === 0) {
      setclickCount(1);
    } else {
    }
    e.preventDefault();
    setinputBackground(true);

    setsendDisableBTN(true);
    // if (amount < 10) {
    //   setinputBackground(false);
    //   setsendDisableBTN(false);
    //   setlessthan10usd(true);

    //   return;
    // } else {
    //   setlessthan10usd(false);
    // }

    const newDate = startDate.toISOString();
    const formattedDate = newDate.slice(0, -1);
    const requestData = {
      senderName,
      narration,
      receiverAccount,
      receiverBank: selectedBank,
      receiverName: accountNameDisplay,
      amount: parseFloat(nairaAmount),
      baseCurrency: "USD",
      settlementCurrency: "NGN",
    };

    if (checked) {
      requestData.expectedPayoutDate = formattedDate;
    }

    const request = encryptData(requestData);

    axios
      .post(process.env.REACT_APP_SENDGBP, {
        request,
      })
      .then((response) => {
        console.log("Hey");

        console.log(response);
        const price = response.data.response.amount;
        const transactionFee = response.data.response.fee;
        const transactionReference =
          response.data.response.transactionReference;
        setscheduledDate(response.data.response.scheduledDate);
        setstripeUrl(response.data.response.url);
        settransaction_reference(transactionReference);
        setpricetosEND(price);
        setfeesToDisplay(transactionFee);
      })
      .catch((error) => {
        // Handle the error
      });
  };
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === "" || !emailRegex.test(email)) {
      setsendDisableBTN(true);
    } else {
      setredBorder(false);
      setsendDisableBTN(false);
    }
  }, [email]);
  // const handleDollarPayment = () => {
  //   // setdollarBtn(true);
  //   console.log("jjds");
  //   // const cardNumber1 = cardNumber.replace(/\s/g, ""); // Card Number
  //   // const [firstPart, secondPart] = [expDate.slice(0, 2), expDate.slice(3)];

  //   const expiryMonth = ""; // Card Expiry Month
  //   const expiryYear = ""; // Card Expiry Year
  //   const securityCode = CVV; // Card CVV
  //   const stringToEncode = process.env.React_App_PAYAZA_MERCHANT_KEY;
  //   const base64EncodedString = btoa(stringToEncode);

  //   const auth = `Payaza ${base64EncodedString}`;

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", auth);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     service_type: "Account",
  //     service_payload: {
  //       request_application: "Payaza",
  //       application_module: "USER_MODULE",
  //       application_version: "1.0.0",
  //       request_class: "UsdCardChargeRequest",
  //       first_name: "Anon",
  //       last_name: "Anon",
  //       email_address: "Anon",
  //       phone_number: "0812345678",
  //       amount: `${USDplaceholder}`,
  //       transaction_reference: `${transaction_reference}`,
  //       currency: "USD",
  //       description: "Anon",
  //       card: {
  //         expiryMonth: expiryMonth,
  //         expiryYear: expiryYear,
  //         securityCode: securityCode,
  //         cardNumber: "8382829404",
  //       },
  //       // "callback_url":"https://redirect.com/"
  //     },
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch("https://cards-live.78financials.com/card_charge/", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => {
  //       result = JSON.parse(result);

  //       if (result.statusOk) {
  //         // ///Handle Success Response
  //         // const creq = document.getElementById("creq");
  //         // creq.value = result.formData;
  //         // const form = document.getElementById("threedsChallengeRedirectForm");
  //         // form.setAttribute("action", result.threeDsUrl);
  //         // form.submit();
  //         setsucessfulCheckoutImage(true);
  //         setdollarBtn(false);
  //       } else {
  //         // ///Handle Error
  //         setdollarBtn(false);
  //         // console.log("Error found", result.debugMessage)
  //         alert("Payment Failed: " + result.debugMessage);
  //         // setdollarBtn(false)
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log("Error", error)
  //       alert("Exception Error: " + error.debugMessage);
  //     });

  //   // ///////////Internal Payment Notification
  //   window.addEventListener("message", (event) => {
  //     const response = JSON.parse(event.data);
  //     // console.log("Payment Notification", response)
  //     if (response.statusOk === true && response.paymentCompleted === true) {
  //       // ////Handle payment successful, do business logic
  //       alert("Payment Successful");
  //       window.open("https://fast.com/");
  //     } else {
  //       // ///Handle Failed payment
  //       alert("Payment Failed");
  //     }
  //   });
  // };
  const newsLetterHandle = (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(newsLetterEmail)) {
      return;
    }

    axios
      .post(process.env.REACT_APP_EMAIL_SUBS, {
        email: newsLetterEmail,
      })
      .then((response) => {
        setnewsLetterEmail("");
        setnewsLetterstate(true);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_RATES)
      .then((response) => {
        setfetchedRate(response.data.response[0].amount);
      })
      .catch((error) => {});
  }, []);

  // const handleDollarPayment = () => {
  //   const payazaCheckout = PayazaCheckout.setup({
  //     connection_mode: PayazaCheckout.TEST_CONNECTION_MODE, // PayazaCheckout.LIVE_CONNECTION_MODE || PayazaCheckout.TEST_CONNECTION_MODE
  //     checkout_amount: Number(2000),
  //     currency_code: "USD", // default is NGN. This field is optional
  //     email_address: "example@email.com",
  //     phone_number: "+1200000000",
  //     first_name: "jjss",
  //     last_name: "snsnsns",
  //     transaction_reference: "your_reference",
  //     onClose: function () {},
  //     callback: function (callbackResponse) {},
  //   });

  //   // Alternatively, you can set the onClose and callback function as described below
  //   function callback(callbackResponse) {}

  //   function onClose() {}

  //   payazaCheckout.setCallback(callback);
  //   payazaCheckout.setOnClose(onClose);

  //   // Display popup
  //   payazaCheckout.showPopup();
  // };
  const openSendModal = () => {
    setsendUSDMODAL(true);
    if (email != "") {
      //   const requestData = {
      //     email,
      //     transactionReference: transaction_reference,
      //   };
      //   console.log(requestData);
      axios
        .post(process.env.REACT_APP_EMAIL_SUBS, {
          email,
          transactionReference: transaction_reference,
        })
        .then((response) => {})
        .catch((error) => {});
    }
    setsendUSDMODAL(true);
    // window.location.href = stripeUrl;
    // e.preventDefault();
  };

  function handleInput(event) {
    const input = event.target;
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
    }
  }

  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };
  useEffect(() => {
    if (!isNaN(nairaAmount) && nairaAmount >= 50) {
      const nairaToDebit = nairaAmount / fetchedRate;
      setUSDplaceholder(nairaToDebit);
    } else {
      setUSDplaceholder("");
    }
  }, [nairaAmount, fetchedRate]);

  //   useEffect(() => {
  //     if (nairaAmount < 10 || nairaAmount > 50) {
  //       setpoundsRange(true);
  //     } else {
  //       setpoundsRange(false);
  //     }
  //   }, [nairaAmount]);
  return (
    <section className={classes.section}>
      <nav className={classes.nav}>
        <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link>
      </nav>

      {sendInitialState && (
        <div className={classes.textBox}>
          <div className={classes.header}>
            <h1>
              Securely Send and <br /> Receive
            </h1>
            <p>without revealing who you are</p>
          </div>
          <h3>
            Anonymous Payments <br /> Made Easy
          </h3>
          <div className={classes.leftBottom}>
            <span onClick={() => sethowToUseCarousel(true)}>How to use</span>
            <span onClick={() => setcontatctUs(true)}>Contact Us</span>
            <span onClick={() => setgetUpdates(true)}>Get Updates</span>
          </div>
          <span className={classes.copywright}>
            © SentByAnon 2023. All rights reserved.
          </span>
        </div>
      )}

      {!sendInitialState && (
        <NewSendTextBox
          sethowToUseCarousel={sethowToUseCarousel}
          setcontatctUs={setcontatctUs}
          setgetUpdates={setgetUpdates}
        />
      )}
      <div className={classes.formBox}>
        <div className={classes.formWrapper}>
          <div className={classes.mainBox}>
            {!sucessfulCheckoutImage ? (
              <div className={classes.mainBox}>
                {header && (
                  <div className={classes.sendOrreceive}>
                    <p
                      className={`${classes.send}  ${
                        isSendActive ? classes.blackBG : classes.whiteBG
                      }`}
                    >
                      Send
                    </p>
                  </div>
                )}

                {/* {sendUSDMODAL && (
                  <>
                    <div className={classes.overlay}></div>
                    <div className={classes.modal}>
                      <div className={classes.usdModalWrapper}>
                        <div>
                          <div className={classes.cardDetailsHeader}>
                            <h3>Card Details</h3>
                          </div>

                          <div>
                            <p>Card Number</p>
                            <input
                              className={classes.cardNumber}
                              type="text"
                              maxLength="19"
                              value={cardNumber}
                              onChange={(e) => setcardNumber(e.target.value)}
                              onInput={(e) => {
                                let value = e.target.value.replace(/\s/g, ""); // Remove existing spaces
                                value = value.replace(/(\d{4})/g, "$1 "); // Add a space after every 4 characters
                                e.target.value = value.trim(); // Update the input value
                              }}
                              placeholder="X X X X  X X X X   X X X X   X X X X"
                            />
                          </div>

                          <div className={classes.monthNcvv}>
                            <div className={classes.expDate}>
                              <p>Exp. Date</p>
                              <input
                                value={expDate}
                                onChange={(e) => setexpDate(e.target.value)}
                                type="text"
                                maxLength="5"
                                onInput={(e) => {
                                  let value = e.target.value.replace(/\//g, ""); // Remove existing slashes
                                  value = value.replace(
                                    /(\d{2})(\d{0,2})/,
                                    "$1/$2"
                                  ); // Add a slash after the first 2 characters
                                  e.target.value = value.trim(); // Update the input value
                                }}
                                placeholder="MM/YY"
                              />
                            </div>

                            <div className={classes.cvv}>
                              <p>CVV</p>
                              <input
                                value={CVV}
                                onChange={(e) => setCVV(e.target.value)}
                                type="number"
                                maxLength="3"
                                onInput={(e) => {
                                  if (e.target.value.length > 3) {
                                    e.target.value = e.target.value.slice(0, 3);
                                  }
                                }}
                                placeholder="X X X"
                              />
                            </div>
                          </div>

                          <div className={classes.usdBtn}>
                            <button
                              disabled={dollarBtn}
                              style={{
                                backgroundColor: dollarBtn ? "#CCCCCC" : "aaa",
                              }}
                              onClick={handleDollarPayment}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )} */}
                {!header && (
                  <div className={classes.receiverDetails}>
                    <p>Summary</p>
                  </div>
                )}
                {isSendActive && (
                  <form onSubmit={sendCash} className={classes.main}>
                    <div className={classes.bank}>
                      <div className={classes.bankBox} onClick={getAllBanks}>
                        <label
                          className={sendInitialState ? "" : classes.boldText}
                        >
                          {selectedBank === "" ? "Bank" : selectedBank}
                        </label>
                        {dropdown && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        )}
                      </div>

                      {sendModalState && (
                        <>
                          <div className={classes.overlay}></div>
                          <div className={classes.modal}>
                            {initialModalOptions && (
                              <>
                                <SendMethodModal
                                  accountNameDisplay={accountNameDisplay}
                                  receiverAccount={receiverAccount}
                                  selectedBank={selectedBank}
                                  setmodalReceiveOption={setmodalReceiveOption}
                                  modalReceiveOption={modalReceiveOption}
                                  setsendModalState={setsendModalState}
                                  pricetosEND={pricetosEND}
                                  transaction_reference={transaction_reference}
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {contatctUs && (
                        <>
                          <div className={classes.overlay}></div>
                          <div className={classes.modal}>
                            {initialModalOptions && (
                              <ContactUs setcontatctUs={setcontatctUs} />
                            )}
                          </div>
                        </>
                      )}

                      {bankList && (
                        <SendBankList
                          sendInitialState={sendInitialState}
                          setSearchTerm={setSearchTerm}
                          setbankList={setbankList}
                          setselectedBank={setselectedBank}
                          filteredBankList={filteredBankList}
                          searchTerm={searchTerm}
                        />
                      )}
                    </div>
                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Account Number"
                      value={receiverAccount}
                      // onChange={trackInput}
                      onChange={(e) => setreceiverAccount(e.target.value)}
                      onInput={handleInput}
                      type="number"
                    />
                    <input
                      style={{
                        color:
                          accountNameDisplay ===
                          "Name Enquiry failed: Invalid Account"
                            ? "red"
                            : "",
                      }}
                      // className={sendInitialState ? "" : classes.boldText}
                      readOnly
                      placeholder={
                        accountNameDisplay === undefined ||
                        receiverAccount.length < 10
                          ? accountNamePlaceHolder
                          : accountNameDisplay
                      }
                      value={receiverName}
                      onChange={(e) => setreceiverName(e.target.value)}
                      type="text"
                      className={`${sendInitialState ? "" : classes.boldText} ${
                        accountNameDisplay ===
                        "Name Enquiry failed: Invalid Account"
                          ? classes.redPlaceholder
                          : ""
                      }`}
                    />
                    {feesToDisplay != "" ? (
                      <div className={classes.border}>
                        <ReactCountryFlag
                          className={classes.flag}
                          countryCode="NG"
                          style={{
                            fontSize: "3em",
                            lineHeight: "1em",
                          }}
                        />
                        <input
                          placeholder={
                            USDplaceholder ? `₦${USDplaceholder}` : ""
                          }
                          readOnly
                        />
                      </div>
                    ) : (
                      <>
                        <div className={classes.border}>
                          <div className={classes.flag}>
                            <ReactCountryFlag
                              countryCode="NG"
                              style={{
                                fontSize: "3em",
                                lineHeight: "1em",
                              }}
                            />
                            <p>NGN</p>
                          </div>

                          <input
                            type="number"
                            value={nairaAmount}
                            placeholder={`Please enter the amount you wish to send ${
                              accountNameDisplay != undefined &&
                              accountNameDisplay != "Getting Account Name..."
                                ? `to ${accountNameDisplay} in NGN`
                                : "in NGN"
                            } `}
                            onChange={(e) => {
                              const value = Math.min(
                                parseInt(e.target.value),
                                850000
                              );
                              setnairaAmount(value);
                            }}
                          />
                        </div>

                        <div className={classes.flexConversion}>
                          <div className={classes.conversion}>
                            <h3>$1/{fetchedRate}</h3>
                            <p
                              className={classes.exchangeRate}
                              style={{
                                color: "purple",
                              }}
                            >
                              Rate(USD/NGN)
                            </p>
                          </div>
                          <div className={classes.basis}>
                            <div
                              className={`${classes.border} ${classes.basis}`}
                            >
                              <div className={classes.flag}>
                                <ReactCountryFlag
                                  countryCode="US"
                                  style={{
                                    fontSize: "3em",
                                    lineHeight: "1em",
                                  }}
                                />
                                <p>USD</p>
                              </div>
                              <input
                                placeholder={
                                  USDplaceholder ? USDplaceholder : ""
                                }
                                readOnly
                              />
                            </div>
                            <p
                              style={{
                                color:
                                  USDplaceholder <= 50 || USDplaceholder >= 1000
                                    ? "purple"
                                    : "white",
                              }}
                            >
                              You can only send between $50- $1000
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {feesToDisplay === "" ? (
                      ""
                    ) : (
                      <div className={classes.emailBox}>
                        Email
                        <input
                          style={{
                            border: redBorder ? " 2px solid red " : "",
                            outline: "none",
                            borderRadius: "6px",
                          }}
                          required
                          type="email"
                          placeholder="To receive the transaction receipt, kindly provide your email address. "
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          //   placeholder={`$${feesToDisplay}`}
                        />
                      </div>
                    )}

                    {feesToDisplay === "" ? (
                      ""
                    ) : (
                      <div>
                        Fees
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={`$${feesToDisplay}`}
                        />
                      </div>
                    )}

                    {feesToDisplay === "" ? (
                      ""
                    ) : (
                      <div>
                        Amount In Dollar
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={` $ ${USDplaceholder} `}
                        />
                      </div>
                    )}

                    {dollarEquivalent === "" ? (
                      ""
                    ) : (
                      <div>
                        Recepient gets:
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={` ${dollarEquivalent} in  Naira`}
                        />
                      </div>
                    )}

                    {dollarFees === "" ? (
                      ""
                    ) : (
                      <div>
                        Fees IN USD
                        <input
                          className={
                            inputBackground ? classes.inputBackground : ""
                          }
                          readOnly
                          type="number"
                          placeholder={`$ ${dollarFees}`}
                        />
                      </div>
                    )}
                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Narration"
                      value={narration}
                      onChange={(e) => setnarration(e.target.value)}
                      type="text"
                    />

                    <input
                      className={inputBackground ? classes.inputBackground : ""}
                      readOnly={readOnly}
                      required
                      placeholder="Sender Name"
                      value={senderName}
                      onChange={(e) => setsenderName(e.target.value)}
                      type="text"
                    />

                    <div
                      className={`   ${
                        sendDisableBTN ? classes.formBtn : classes.formBtnActive
                      }`}
                    >
                      {/* <button
                        style={{ background: sendDisableBTN ? "#CCCCCC" : "" }}
                        onClick={() =>
                          !btnLoading
                            ? changeinitialSendState(
                                setsendInitialState,
                                setinputBackground,
                                setbtnLoading,
                                setheader,
                                amount,
                                accountNameDisplay,
                                senderName,
                                setdropdown,
                                setreadOnly,
                                setaccountNamePlaceHolder
                              )
                            : handleDollarPayment()
                        }
                        disabled={sendDisableBTN}
                        type={clickCount === 0 ? "submit" : "button"}
                      >
                        {!btnLoading ? "Proceed" : "Proceed to payment"}
                      </button> */}
                    </div>
                  </form>
                )}
              </div>
            ) : (
              <SuccessfulTransactionImage
                transactionRef={transaction_reference}
              />
            )}

            {getUpdates && (
              <>
                <div className={classes.overlay}></div>
                <div className={classes.modal}>
                  {initialModalOptions && (
                    <Email
                      setgetUpdates={setgetUpdates}
                      setnewsLetterstate={setnewsLetterstate}
                      newsLetterEmail={newsLetterEmail}
                      setnewsLetterEmail={setnewsLetterEmail}
                      newsLetterstate={newsLetterstate}
                    />
                  )}
                </div>
              </>
            )}

            {howToUseCarousel && (
              <div>
                <div
                  onClick={() => sethowToUseCarousel(false)}
                  className={classes.overlay}
                ></div>

                <CarouselPage />
              </div>
            )}
            {/* onClick={!btnLoading ? changeinitialSendState : handleSendPayment} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PoundsPayment;
