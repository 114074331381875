import React, { createContext, useState } from "react";

export const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [isReceiveActive, setisReceiveActive] = useState(false);
  const [isSendActive, setisSendActive] = useState(true);

  const updateReceiveActive = () => {
    setisReceiveActive((prevState) => !prevState);
  };
  const updateSendActive = () => {
    setisSendActive((prevState) => !prevState);
  };

  return (
    <AppStateContext.Provider
      value={{
        isReceiveActive,
        isSendActive,
        setisReceiveActive,
        setisSendActive,
        updateReceiveActive,
        updateSendActive,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
