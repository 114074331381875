import React, {useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import classes from '../SendPage/SendAndReceive.module.css'

const CarouselPage = () => {
  return (
    <Carousel className={classes.modal} >
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324938/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.07_AM_puzpk2.jpg" alt='howtousesentbyanon' />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324940/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_n7wgag.jpg" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324941/sentbyanon/WhatsApp_Image_2023-06-06_at_5.16.13_AM_1_bcilpt.jpg" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324945/sentbyanon/WhatsApp_Image_2023-06-06_at_2.06.51_PM_qirsti.jpg" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686324947/sentbyanon/WhatsApp_Image_2023-06-06_at_2.07.00_PM_ugdjab.jpg" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326221/sentbyanon/www.sentbyanon.com_3_wmtier.png" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686347898/sentbyanon/www.sentbyanon.com_7_ta4zhh.png" alt='howtousesentbyanon'  />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326020/sentbyanon/www.sentbyanon.com_1_nz1zly.png" alt='howtousesentbyanon' />

    </div>
    <div>
        <img src="https://res.cloudinary.com/da3avivzn/image/upload/v1686326015/sentbyanon/www.sentbyanon.com_2_gquke4.png" alt='howtousesentbyanon' />

    </div>

</Carousel>

  )
}

export default CarouselPage