const TeamData = () => [
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687611899/sentbyanon/Jonas_xk5ykn.png",
    name: "John",
    role: "Product Lead/ Backend Developer",
  },
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687611900/sentbyanon/Erick_ymcmdx.png",
    name: "Simplicity",
    role: "Frontend Developer",
  },
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687612016/sentbyanon/Mark_pgs04r.png",
    name: "Victor",
    role: "Product Designer",
  },
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687611903/sentbyanon/Ruby_mxpwks.png",
    name: "Blessing",
    role: "Social Media Manager",
  },
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687611902/sentbyanon/Hannah_tprew9.png",
    name: "Nora",
    role: "Customer Care Rep.",
  },
  {
    img: "https://res.cloudinary.com/da3avivzn/image/upload/v1687611901/sentbyanon/Betty_kopca2.png",
    name: "Osais",
    role: "Brand Designer",
  },
];
export default TeamData;
