import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import classes from "./AnonLinkPayment.module.css";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import PayazaCheckout from "payaza-web-sdk";

import Nav from "../../NavigationMobile/NavigationMobile";

const AnonrefPayment = () => {
  const [username, setusername] = useState("");
  const [amount, setamount] = useState("");
  const [narration, setnarration] = useState("");
  const [ref, setref] = useState("");
  const [transaction_reference, settransaction_reference] = useState("");
  const [loading, setloading] = useState(false);
  const [isbtnClicked, setisbtnClicked] = useState(false);
  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };

  useEffect(() => {
    // Declare variables for event reference and URL parameters
    let ref = "";
    let name = "";
    // let urlParams = "";

    // Get the current URL of the page
    const url = new URL(window.location.href);

    // Extract the path from the URL
    const path = url.pathname;

    const pathLastIndex = path.length;

    // Extract URL parameters from the search string
    // urlParams = new URLSearchParams(window.location.search);

    // Extract the event reference from the path (substring from index 7 to 14)
    ref = path.substring(1, 20);
    name = path.substring(21, pathLastIndex);

    setusername(name);
    setref(ref);
    const mainLink = process.env.REACT_APP_SEND_CASH;
    // Construct the URL for the API call using the event reference
    const endLink = `${mainLink}/${ref}/${name}`;
    const requestData = {
      linkRef: ref,
      name,
    };
    const request = encryptData(requestData);

    // Perform an Axios GET request to fetch data related to the event
    axios
      .get(`${endLink}`, {
        request,
      })
      .then((response) => {
        // Handle the API response here
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
      });
  }, []);
  useEffect(() => {
    if (amount.length < 3 || narration.length < 2) {
      setloading(false);
    } else {
      setloading(true);
    }
  }, [amount, narration]);

  const getTransactionRef = (e) => {
    e.preventDefault();
    console.log("called");
    if (!loading) {
      return;
    }

    setloading(true);
    setisbtnClicked(true);

    const requestData = {
      linkRef: ref,
      amount,
      narration,
    };

    const request = encryptData(requestData);
    axios
      .post(process.env.REACT_APP_SEND_CASH, { request })
      .then((response) => {
        settransaction_reference(response.data.response.paymentRef);
        handleNairaPayment(response.data.response.paymentRef);
        setloading(false);
        setisbtnClicked(false);
      })
      .catch((error) => {})
      .finally(() => {
        // handleNairaPayment();
      });
  };

  const handleNairaPayment = (ref) => {
    const payazaCheckout = PayazaCheckout.setup({
      merchant_key: process.env.React_App_PAYAZA_MERCHANT_KEY,
      connection_mode: "Live", // Live || Test
      checkout_amount: parseFloat(amount),
      currency_code: "NGN", // default is NGN. This field is optional
      email_address: "cashflow@sentbyanon.com",
      first_name: "Anon",
      last_name: "Anon",
      transaction_reference: ref,
      onClose: function () {
        // console.log("Closed")
      },
      callback: function (callbackResponse) {
        // console.log("Response Is Here: ", callbackResponse)
        // console.log(callbackResponse)
        // console.log(callbackResponse.status)
      },
    });

    // Alternatively, you can set the onClose and callback function as described below
    function callback(callbackResponse) {}

    function onClose() {
      console.log("closed");
    }

    payazaCheckout.setCallback(callback);
    payazaCheckout.setOnClose(onClose);

    // Display popup
    payazaCheckout.showPopup();
  };
  return (
    <section className={classes.section}>
      <nav className={classes.nav}>
        {/* <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link> */}
        <Nav />
      </nav>
      <main className={classes.main}>
        <div className={classes.leftSide}>
          <div className={classes.header}>
            <h1>Surprise&nbsp;Your&nbsp;Loved&nbsp;</h1>
            <h1>One Anonymously</h1>
          </div>

          <h4>{`You're about to send money to  ${username} `}</h4>

          <form className={classes.linkForm}>
            <input
              type="number"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
              placeholder="Amount (₦)"
            />

            <input
              value={narration}
              onChange={(e) => setnarration(e.target.value)}
              placeholder="Narration"
            />

            {/*  <input className={classes.fromLeft} placeholder="*****" />

            <p>Enter Token Sent to provided email address</p>

            <br />
             */}

            <div className={classes.emailInput}>
              {isbtnClicked && <ScaleLoader color="#a800b7" />}
              <Link to="/anonlink">
                <button type="submit" className={classes.generateBTN}>
                  Generate Your Own Link
                </button>
              </Link>
              <button
                disabled={!loading}
                style={{ backgroundColor: !loading ? "#ccc" : "#a800b7" }}
                onClick={getTransactionRef}
              >
                Pay Now
              </button>

              {/* <div>{disabledBTN && <ScaleLoader color="#a800b7" />}</div> */}
            </div>
          </form>
        </div>
        <div className={classes.rightBox}>
          <div className={classes.imgBox}>
            <div className={classes.imgBox}>
              <img
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1691093083/sentbyanon/Group_263_6_n58rju.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default AnonrefPayment;
