import React, { useEffect, useState } from "react";
// import classes from "../SendPage/SendAndReceive.module.css";
import classes from "./MultiplePage.module.css";
import { minGetAllBanks } from "../../Functions/GetAllBanks";
import { getAccountName } from "../../Functions/GetAccountForMultiple";
import { SendBankListForMultiple } from "../SendBankListForMultiple/SendBankListForMultiple";
import axios from "axios";
import CryptoJS from "crypto-js";
import PayazaCheckout from "payaza-web-sdk";
import { ClipLoader } from "react-spinners";
import { ThemeContext } from "../../App";

import SuccessfulTransactionImage from "../SuccessfulTransactionImage/SuccessfulTransactionImage";
import { Link } from "react-router-dom";
import Nav from "../../NavigationMobile/NavigationMobile";
import { useContext } from "react";
const MultiplePage = () => {
  const [bankList, setbankList] = useState(false);
  const [bankListFromApi, setbankListFromApi] = useState([]);
  const [selectedBank, setselectedBank] = useState("");
  const [dropdown, setdropdown] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sendInitialState, setsendInitialState] = useState(true);
  const [accountName, setaccountName] = useState("Account Name");
  const [totalAmount, settotalAmount] = useState(0);

  const { theme } = useContext(ThemeContext);
  const filteredBankList = bankListFromApi.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [accountNumber, setaccountNumber] = useState("");
  const [sucessfulCheckoutImage, setsucessfulCheckoutImage] = useState(false);
  const [isbuttonDisabled, setisbuttonDisabled] = useState(true);

  const [request1, setrequest1] = useState(null);
  const [narration, setnarration] = useState("");
  const [amount, setamount] = useState("");
  const [senderName, setsenderName] = useState("");
  const [accountNameDisplay, setaccountNameDisplay] = useState("");
  const [subRequests, setsubRequests] = useState([]);
  const [senderNameTosend, setsenderNameTosend] = useState("");
  const [maxTransaction, setmaxTransaction] = useState(false);
  const [responseData, setresponseData] = useState([]);
  const [summaryBox, setsummaryBox] = useState(false);
  const [spinner, setspinner] = useState(false);
  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };

  const handleAccountNumLim = (e) => {
    const lim = 10;
    setaccountNumber(e.target.value.slice(0, lim));
  };

  const getAllBanks = () => {
    minGetAllBanks(setbankList, setbankListFromApi);
  };

  useEffect(() => {
    getAccountName(selectedBank, accountNumber, setaccountNameDisplay);
    if (accountNumber.length === 10 && selectedBank) {
      setaccountName("Getting Account Name");
    }
  }, [accountNumber, selectedBank]);
  useEffect(() => {
    if (
      accountNameDisplay &&
      amount &&
      narration &&
      selectedBank &&
      accountNumber
    ) {
      setisbuttonDisabled(false);
    } else {
      setisbuttonDisabled(true);
    }
  }, [accountName, amount, narration, selectedBank, accountNumber]);

  const handlePush = (e) => {
    e.preventDefault();
    setsenderNameTosend(senderName);

    if (subRequests.length === 5) {
      setmaxTransaction(true);
      return;
    }
    if (
      accountNameDisplay &&
      amount &&
      narration &&
      selectedBank &&
      accountNumber
    ) {
      const subRequest = {
        receiverName: accountNameDisplay,
        amount,
        narration,
        receiverBank: selectedBank,
        receiverAccount: accountNumber,
        // senderName: senderName,
      };
      const newtotalAmount = totalAmount + parseFloat(amount);
      settotalAmount(newtotalAmount);
      const updatedSubRequests = [...subRequests, subRequest];
      // const data = {
      //   senderName: senderNameTosend,
      //   subRequests: updatedSubRequests,
      // };
      setsubRequests(updatedSubRequests);
      const dataset = {
        senderName,
        subRequest,
      };
      // setsubRequests([...subRequests, subRequest]);
      // const request1 = JSON.stringify(data, null, 2);
      // console.log(request1);
      // setrequest1(request1);
      setnarration("");
      setaccountNumber("");
      setaccountNameDisplay("");
      setselectedBank("");
      setamount("");
      setsenderName("");
    }
  };
  const handlePayment = (e) => {
    e.preventDefault();

    setspinner(true);

    axios
      .post(process.env.REACT_APP_SEND_CASH, {
        request: encryptData({ subRequests, senderName }),
      })
      .then((response) => {
        setresponseData(response.data.response);
        setspinner(false);
        setsummaryBox(true);
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const totalAmountFunction = (amount) => {
    return amount;
  };
  const handleNairaPayment = () => {
    const payazaCheckout = PayazaCheckout.setup({
      merchant_key: process.env.React_App_PAYAZA_MERCHANT_KEY,
      connection_mode: "Live", // Live || Test
      checkout_amount: responseData.total,
      currency_code: "NGN", // default is NGN. This field is optional
      email_address: "cashflow@sentbyanon.com",
      first_name: "Anon",
      last_name: "Anon",
      transaction_reference: responseData.transactionReference,
      onClose: function () {
        // console.log("Closed")
      },
      callback: function (callbackResponse) {
        // console.log("Response Is Here: ", callbackResponse)
        // console.log(callbackResponse)
        // console.log(callbackResponse.status)
      },
    });

    // Alternatively, you can set the onClose and callback function as described below
    function callback(callbackResponse) {
      // console.log("Response Is Here: ", callbackResponse)
      // console.log(callbackResponse)
      // console.log(callbackResponse.status)
      // console.log(callbackResponse.data.message)

      if (
        callbackResponse.status === 201 &&
        callbackResponse.data.message === "Transaction Successful"
      ) {
        setsucessfulCheckoutImage(true);
      }
    }

    function onClose() {
      // console.log("closed")
    }

    payazaCheckout.setCallback(callback);
    payazaCheckout.setOnClose(onClose);

    // Display popup
    payazaCheckout.showPopup();
  };
  return (
    <section
      className={`${classes.section} ${
        theme === "dark" ? classes.dark : classes.light
      }`}
    >
      <nav className={classes.nav}>
        {/* <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link> */}
        <Nav />
      </nav>
      <main className={classes.sectionWrapper}>
        {!sucessfulCheckoutImage ? (
          <form onSubmit={handlePayment} className={classes.formForMultiple}>
            <div className={classes.accountDetailsHeader}>
              <h4>Account Details</h4>
            </div>
            <div className={classes.formBox}>
              <div className={classes.bankBox} onClick={getAllBanks}>
                <label>{selectedBank === "" ? "Bank" : selectedBank}</label>
                {dropdown && (
                  <svg
                    className={classes.svg}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    // className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </div>
              {bankList && (
                <SendBankListForMultiple
                  sendInitialState={sendInitialState}
                  setSearchTerm={setSearchTerm}
                  setbankList={setbankList}
                  setselectedBank={setselectedBank}
                  filteredBankList={filteredBankList}
                  searchTerm={searchTerm}
                />
              )}

              <div className={classes.inputField}>
                <input
                  required
                  maxLength={12}
                  type="number"
                  value={accountNumber}
                  onChange={handleAccountNumLim}
                  placeholder="Account Number"
                />
                <input
                  required
                  type="number"
                  readOnly
                  placeholder={
                    accountNameDisplay === "" ? accountName : accountNameDisplay
                  }
                />
                <input
                  required
                  type="text"
                  onChange={(e) => setnarration(e.target.value)}
                  value={narration}
                  placeholder="Narration"
                />
                <input
                  required
                  type="number"
                  onChange={(e) => setamount(e.target.value)}
                  value={amount}
                  placeholder="Amount"
                />
                {subRequests.length < 1 ? (
                  <input
                    required
                    type="text"
                    value={senderName}
                    onChange={(e) => setsenderName(e.target.value)}
                    placeholder="Sender Name"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={classes.submitBtns}>
              <button
                disabled={isbuttonDisabled}
                style={{
                  backgroundColor: isbuttonDisabled ? "#B2BEB5" : "",
                }}
                onClick={handlePush}
                className={classes.addToButton}
              >
                Add to Send List
              </button>
            </div>
            {maxTransaction && (
              <h3
                style={{
                  color: "red",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                Max(5) Transaction Reached, click submit to continue
              </h3>
            )}
          </form>
        ) : (
          <SuccessfulTransactionImage
            transactionRef={responseData.transaction_reference}
          />
        )}

        <div className={classes.summarySection}>
          <div className={classes.accountSummaryHeader}>
            <div className={classes.sendListHeader}>
              <h4>{!summaryBox ? "Send List" : "Summary"}</h4>
            </div>

            <div className={classes.summaryBox}>
              {!summaryBox && (
                <div>
                  {subRequests.map((item, index) => (
                    <>
                      <div className={classes.summaryList} key={index}>
                        <div>
                          <h5>{item.receiverAccount}</h5>
                          <h5>{item.receiverBank}</h5>
                          <h5>{item.receiverName}</h5>
                        </div>
                        <div>
                          <p>Amount</p>
                          <h5>₦{item.amount}</h5>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className={classes.summaryList}>
                    <h3 className={classes.total}>Total</h3>
                    <p>₦ {totalAmount}</p>
                  </div>
                </div>
              )}

              {summaryBox && (
                <div className={classes.summaryBox}>
                  <div className={classes.summaryList}>
                    <h5>Amount</h5>₦ {responseData.amount}
                  </div>
                  <div className={classes.summaryList}>
                    <h5>Fee</h5>₦ {responseData.fee}
                  </div>
                  <div className={classes.summaryList}>
                    <h5>Total</h5>
                    {/* {responseData.fee} + {responseData.amount} */}₦{" "}
                    {responseData.total}
                  </div>
                </div>
              )}

              {spinner && (
                <ClipLoader className={classes.center} color="#ac79b0" />
              )}
              {!subRequests.length < 1 ? (
                <div className={classes.submitBtns}>
                  {subRequests && (
                    <button
                      onClick={
                        responseData.length < 1
                          ? handlePayment
                          : handleNairaPayment
                      }
                      className={classes.submitBtn}
                    >
                      {responseData.length < 1 ? "Submit" : "Make payment"}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default MultiplePage;
