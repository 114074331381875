import React, { useState, useEffect, useContext } from "react";
import classes from "./HomePage.module.css";
import CarouselPage from "../Carousel/Carousel";
import { ContactUs } from "../MinorComponents/ContactUs";
import { Link } from "react-router-dom";
import { AppStateContext } from "../AppStateContext";
import Nav from "../../NavigationMobile/NavigationMobile";
import { ThemeContext } from "../../App";
import ReactSwitch from "react-switch";
const HomePage = ({ toggleTheme }) => {
  const { theme } = useContext(ThemeContext); // Access the theme from the context

  const [currentDiv, setCurrentDiv] = useState(1);
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [contactUs, setcontactUs] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  const [dropdownUseSenttByAnon, setdropdownUseSenttByAnon] = useState(false);
  const [navDropDown, setnavDropDown] = useState(false);
  const { isReceiveActive, updateReceiveActive } = useContext(AppStateContext);
  const { isSendActive, updateSendActive } = useContext(AppStateContext);

  const handleClick = () => {
    updateReceiveActive();
    updateSendActive();
  };
  const handleToggleTheme = () => {
    toggleTheme(); // Call the toggleTheme function to switch the theme.
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentDiv((prevDiv) => (prevDiv === 3 ? 1 : prevDiv + 1));
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentDiv]);
  return (
    <section
      // onClick={() => setdropdownUseSenttByAnon(false)}
      className={`${classes.section}  `}
    >
      <Nav />
      {contactUs && (
        <>
          <div className={classes.overlay}></div>
          <div className={classes.modal}>
            {initialModalOptions && <ContactUs setcontatctUs={setcontactUs} />}
          </div>
        </>
      )}{" "}
      {howToUseCarousel && (
        <div>
          <div
            onClick={() => sethowToUseCarousel(false)}
            className={classes.overlay}
          ></div>

          <CarouselPage />
        </div>
      )}
      <div className={classes.mainWrapper}>
        <main className={classes.main}>
          <div className={classes.leftMain}>
            <div className={classes.textBox}>
              <h1>Securely Send and Receive</h1>
              <h2>without revealing who you are</h2>
              <h4>
                Anonymous Payments <br /> Made Easy!
              </h4>
              <div
                className={classes.useSentByAnonBtn}
                // onMouseEnter={() => setdropdownUseSenttByAnon(true)}
                // onMouseLeave={() => setdropdownUseSenttByAnon(false)}
                onClick={() => setdropdownUseSenttByAnon(true)}
              >
                <button>Use SentByAnon</button>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-narrow-down"
                  width="25"
                  height="28"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ffffff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 5l0 14" />
                  <path d="M16 15l-4 4" />
                  <path d="M8 15l4 4" />
                </svg>
              </div>
              <ReactSwitch
                onChange={handleToggleTheme}
                checked={theme === "dark"}
                onColor="#a800b7"
              />
              {dropdownUseSenttByAnon && (
                <>
                  <div
                    onClick={() => setdropdownUseSenttByAnon(false)}
                    className={classes.overlay}
                  ></div>
                  <div
                    className={classes.ctaBox}
                    onClick={(e) => e.stopPropagation()}
                    onMouseEnter={() => setdropdownUseSenttByAnon(true)}
                    onMouseLeave={() => setdropdownUseSenttByAnon(false)}
                  >
                    <Link className={classes.link} to="/sendreceive">
                      <span>Send Money Anonymously</span>
                    </Link>
                    {/* <Link
                      className={classes.link}
                      onClick={handleClick}
                      to="/sendreceive"
                    >
                      <span>Receive Money Anonymously</span>

                    </Link> */}
                    <Link className={classes.link} to="/sendreceive/multiple">
                      <span>Send money to multiple account</span>
                    </Link>
                    <Link to="/sendreceive" className={classes.link}>
                      <span>Schedule a payment</span>
                    </Link>
                    <Link className={classes.link} to="/anonlink">
                      <span>
                        Get Anon Link <p style={{ color: "#a800b7" }}>(New)</p>
                      </span>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={classes.rightMain}>
            <div className={classes.wrapper}>
              {currentDiv === 1 && (
                <img
                  className={`${classes.slidediv}${classes.div1}`}
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1687289048/Group_173_wyqgso.png"
                  alt=""
                />
              )}
              {currentDiv === 2 && (
                <img
                  className={`${classes.sliddiv}${classes.div2}`}
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1687293317/Component_1_e09oe2.png"
                  alt=""
                />
              )}
              {currentDiv === 3 && (
                <img
                  className={`${classes.slidediv}${classes.div3}`}
                  src="https://res.cloudinary.com/da3avivzn/image/upload/v1687293319/Component_2_p9tnbi.png"
                  alt=""
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default HomePage;
