import { createContext, useState } from "react";

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [sharedTransactionRef, setsharedTransactionRef] = useState("");

  return (
    <StateContext.Provider
      value={{ sharedTransactionRef, setsharedTransactionRef }}
    >
      {children}
    </StateContext.Provider>
  );
};
