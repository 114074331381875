import classes from "../SendPage/SendAndReceive.module.css";
import PayazaCheckout from "payaza-web-sdk";
const SendMethodModal = ({
  setmodalReceiveOption,

  accountNameDisplay,
  receiverAccount,
  selectedBank,
  modalReceiveOption,
  setsendModalState,
  pricetosEND,
  transaction_reference,
  setsucessfulCheckoutImage,
}) => {
  const multipleFunctionButtonForSend = (e) => {
    if (modalReceiveOption === "Naira") {
      setsendModalState(false);
      handleNairaPayment();

      // handleSendPayment()
    }
  };
  const handleNairaPayment = () => {
    const payazaCheckout = new PayazaCheckout({
      merchant_key: process.env.React_App_PAYAZA_MERCHANT_KEY,
      connection_mode: "Live", // Live || Test
      checkout_amount: pricetosEND,
      currency_code: "NGN", // default is NGN. This field is optional
      email_address: "cashflow@sentbyanon.com",
      first_name: "Anon",
      last_name: "Anon",
      transaction_reference: transaction_reference,
      onClose: function () {
        // console.log("Closed")
      },
      callback: function (callbackResponse) {
        // console.log("Response Is Here: ", callbackResponse)
        // console.log(callbackResponse)
        // console.log(callbackResponse.status)
      },
    });

    // Alternatively, you can set the onClose and callback function as described below
    function callback(callbackResponse) {
      // console.log("Response Is Here: ", callbackResponse)
      // console.log(callbackResponse)
      // console.log(callbackResponse.status)
      // console.log(callbackResponse.data.message)

      if (
        callbackResponse.status === 201 &&
        callbackResponse.data.message === "Transaction Successful"
      ) {
        setsucessfulCheckoutImage(true);
      }
    }

    function onClose() {
      // console.log("closed")
    }

    payazaCheckout.setCallback(callback);
    payazaCheckout.setOnClose(onClose);

    // Display popup
    payazaCheckout.showPopup();
  };
  return (
    <>
      <h3>Send Method</h3>
      <p>
        How would you like to send money to {accountNameDisplay},{" "}
        {receiverAccount}
        {selectedBank} ?
      </p>
      <div className={classes.checkBox}>
        <inpu />
        <label>Dollar(Coming Soon)</label>
      </div>

      <div
        onClick={() => setmodalReceiveOption("Naira")}
        className={classes.checkBox}
      >
        <input type="radio" id="naira" name="receiveMethod" value="naira" />
        <label htmlFor="naira">ATM Card or Bank Transfer(Available)</label>
      </div>
      <div className={classes.checkBox}>
        <inpu />
        <label>Crypto(Coming Soon)</label>
      </div>
      <div>
        <button onClick={multipleFunctionButtonForSend}>Continue</button>
      </div>
    </>
  );
};

export default SendMethodModal;
