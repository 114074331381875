import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { minGetAllBanks } from "../../Functions/GetAllBanks";
import { SendBankList } from "../SendBankList/SendBankList";
import { getAccountName } from "../../Functions/GetAccountName";
import Nav from "../../NavigationMobile/NavigationMobile";
import { ThemeContext } from "../../App";
const Dashboard = () => {
  const [email, setemail] = useState("");
  const [isTokenAvailable, setisTokenAvailable] = useState(false);
  const [disabledBTN, setdisabledBTN] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [token, settoken] = useState("");
  const [totalBalance, settotalBalance] = useState("");
  const [paymentsArray, setpaymentsArray] = useState([]);
  const [isWithdrawalClicked, setisWithdrawalClicked] = useState(false);
  const [sendInitialState, setsendInitialState] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBank, setselectedBank] = useState("");
  const [bankList, setbankList] = useState(false);
  const [bankListFromApi, setbankListFromApi] = useState([]);
  const [receiverAccount, setreceiverAccount] = useState("");
  const [accountNameDisplay, setaccountNameDisplay] = useState("");
  const [num10state, setnum10state] = useState("");
  const { theme } = useContext(ThemeContext);
  const [accountNamePlaceHolder, setaccountNamePlaceHolder] =
    useState("Account Name");
  const [inValidToken, setinValidToken] = useState(false);
  const [amountToWidthraw, setamountToWidthraw] = useState("");
  const [withdrawalisBeingProcessed, setwithdrawalisBeingProcessed] =
    useState(false);
  const [link, setlink] = useState("");
  const [isGreater, setisGreater] = useState(false);
  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };

  useEffect(() => {
    if (receiverAccount.length < 10) {
      setaccountNamePlaceHolder("Account Name");
    } else if (receiverAccount.length === 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    } else if (receiverAccount.length < 10 && selectedBank !== "") {
      setaccountNamePlaceHolder("Getting Account Name ....");
    }
  }, [receiverAccount, selectedBank]);
  useEffect(() => {
    if (num10state === "is 10" && receiverAccount.length < 10) {
      setaccountNameDisplay("Getting Account Name...");
    }
    getAccountName(
      receiverAccount,
      selectedBank,
      setaccountNameDisplay,
      accountNameDisplay,
      num10state,
      setnum10state
    );
  }, [receiverAccount, selectedBank]);
  const filteredBankList = bankListFromApi.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getAllBanks = () => {
    minGetAllBanks(setbankList, setbankListFromApi);
  };
  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setdisabledBTN(true);
    } else {
      setdisabledBTN(false);
    }
  }, [email]);

  const verifyTokenHandler = (e) => {
    e.preventDefault();

    if (token.length < 10) {
      return;
    }

    const requestData = {
      email,
      token,
    };

    const request = encryptData(requestData);
    setisLoading(true);
    setdisabledBTN(true);
    const endPoint = process.env.REACT_APP_SEND_CASH;
    axios
      .post(`${endPoint}`, { request })
      .then((response) => {
        const list = response.data.response;

        if (list != "Authorization failed") {
          settotalBalance(list.balance);
          setpaymentsArray(list.payments);
          setisLoading(false);
          setisTokenAvailable(true);
          setisEmailVerified(true);
          setinValidToken(false);
        } else {
          setisLoading(false);
          setdisabledBTN(false);
          setinValidToken(true);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (amountToWidthraw > totalBalance) {
      setisGreater(true);
    } else {
      setisGreater(false);
    }
  }, [amountToWidthraw]);

  const withdrawalHandler = (e) => {
    e.preventDefault();
    if (amountToWidthraw > totalBalance) {
      return;
    }
    const pathname = new URL(link).pathname;

    // Split the pathname using "/" as the separator and get the second element (index 1)
    const pathElements = pathname.split("/");
    const extractedPath = pathElements[1];

    const requestData = {
      email: email,
      linkRef: extractedPath,
      authorization: token,
      amount: amountToWidthraw,
      bank: selectedBank,
      accountNumber: receiverAccount,
      accountName: accountNameDisplay,
    };

    const request = encryptData(requestData);
    setisLoading(true);
    setdisabledBTN(true);
    const endPoint = process.env.REACT_APP_SEND_CASH;
    axios
      .post(`${endPoint}`, { request })
      .then((response) => {
        setwithdrawalisBeingProcessed(true);
      })
      .catch((error) => {});
  };

  const getTokenHandler = (e) => {
    e.preventDefault();
    setisLoading(true);
    setdisabledBTN(true);
    const endPoint = process.env.REACT_APP_SEND_CASH;
    axios
      .get(
        `https://router-sentbyanon-e9a59f774616.herokuapp.com/sendVerification/${email}`
      )
      .then((response) => {
        setdisabledBTN(false);
        setisLoading(false);
        setisTokenAvailable(true);
      })
      .catch((error) => {});
  };
  return (
    <section
      className={`${classes.section} ${
        theme === "dark" ? classes.dark : classes.light
      }`}
    >
      <nav className={classes.nav}>
        {/* <Link to="/">
          <img
            src="
                https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            alt=""
          />
        </Link> */}
        <Nav />
      </nav>
      <main className={classes.main}>
        <div className={classes.leftSide}>
          {!isEmailVerified && (
            <form className={classes.linkForm}>
              {!isEmailVerified && (
                <>
                  <div className={classes.header}>
                    <h1>Kindly&nbsp;Provide&nbsp;</h1>
                    <h1>Your Email Address</h1>
                  </div>
                  <br />
                  <input
                    placeholder="Input Your Email Address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </>
              )}
              {isTokenAvailable && !isEmailVerified && (
                <>
                  <input
                    onChange={(e) => settoken(e.target.value)}
                    className={classes.fromLeft}
                    placeholder="*****"
                  />
                  <p>Enter Token Sent to provided email address</p>
                </>
              )}

              <br />
              {isTokenAvailable && inValidToken && (
                <>
                  <p>Inalid Token</p>
                </>
              )}
              {!isEmailVerified && (
                <div className={classes.emailInput}>
                  <button
                    type="button"
                    disabled={disabledBTN}
                    style={{ backgroundColor: disabledBTN ? "#ccc" : "" }}
                    onClick={
                      isTokenAvailable ? verifyTokenHandler : getTokenHandler
                    }
                  >
                    {isTokenAvailable ? "View Dashboard" : "Get Token"}
                  </button>

                  <div>{isLoading && <ScaleLoader color="#a800b7" />}</div>
                </div>
              )}
            </form>
          )}

          {isEmailVerified && !isWithdrawalClicked && (
            <div className={classes.tableWrapper}>
              <div className={classes.tableHeader}>
                <h4>Dashboard</h4>
              </div>
              <div className={classes.subheading}>
                <>
                  <h6>Name</h6>
                  <h6>Amount</h6>
                </>
              </div>
              {paymentsArray.map((item) => (
                <div className={classes.subheadingText}>
                  <p>{item.sender}</p>
                  <p>{item.amount}</p>
                </div>
              ))}

              <div className={classes.amountBox}>
                <h3>Total: {totalBalance}</h3>
                <div>
                  <button onClick={() => setisWithdrawalClicked(true)}>
                    Process Withdrawal
                  </button>
                </div>
              </div>
            </div>
          )}
          {isWithdrawalClicked && !withdrawalisBeingProcessed && (
            <>
              <div className={classes.header}>
                <h1>Kindly&nbsp;Provide&nbsp;Your&nbsp;</h1>
                <h1>Bank Details</h1>
              </div>
              <h4>{`Available Balance: ${totalBalance} `}</h4>
            </>
          )}
          {isWithdrawalClicked && !withdrawalisBeingProcessed && (
            <div className={classes.linkForm}>
              <input
                onClick={getAllBanks}
                placeholder={selectedBank === "" ? "Bank" : selectedBank}
                readOnly
                required
                onChange={(e) => setselectedBank(e.target.value)}
              />

              {bankList && (
                <div className={classes.bankList}>
                  <SendBankList
                    sendInitialState={sendInitialState}
                    setSearchTerm={setSearchTerm}
                    setbankList={setbankList}
                    setselectedBank={setselectedBank}
                    filteredBankList={filteredBankList}
                    searchTerm={searchTerm}
                  />
                </div>
              )}
              <input
                placeholder="Account Number"
                type="number"
                required
                value={receiverAccount}
                onChange={(e) => setreceiverAccount(e.target.value)}
              />
              <input
                placeholder={
                  accountNameDisplay === undefined ||
                  receiverAccount.length < 10
                    ? accountNamePlaceHolder
                    : accountNameDisplay
                }
                readOnly
                required
              />
              <input
                placeholder="Input Your Link"
                type="text"
                value={link}
                required
                onChange={(e) => setlink(e.target.value)}
              />

              <input
                placeholder="Amount"
                type="number"
                value={amountToWidthraw}
                required
                onChange={(e) => setamountToWidthraw(e.target.value)}
              />
              {isGreater && (
                <p>Amount can`t be greater than available balance</p>
              )}
              <div className={classes.amountBox}>
                <div>
                  <button type="button" onClick={withdrawalHandler}>
                    Withdraw
                  </button>
                </div>
              </div>
            </div>
          )}
          {withdrawalisBeingProcessed && (
            <div className={classes.header}>
              <h1>Withdrawal&nbsp;Is&nbsp;Now</h1>
              <h1>Being Processed</h1>
            </div>
          )}
        </div>
        <div className={classes.rightBox}>
          <div className={classes.imgBox}>
            <div className={classes.imgBox}>
              <img
                src="https://res.cloudinary.com/da3avivzn/image/upload/v1691093083/sentbyanon/Group_263_6_n58rju.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default Dashboard;
