import axios from "axios";
import CryptoJS from "crypto-js";

export const getAccountName = (
  selectedBank,
  accountNumber,
  setaccountNameDisplay
) => {
  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };
  // Check if receiverAccount has a length of 10 and selectedBank is truthy
  if (accountNumber.length === 10 && selectedBank) {
    axios
      .post(process.env.REACT_APP_NAME_ENQUIRY, {
        request: encryptData({
          accountNumber: accountNumber,
          bankName: selectedBank,
        }),
      })
      .then((response) => {
        // Update the accountNameDisplay state with the received account name

        setaccountNameDisplay(response.data.response.account_name);
      })
      .catch((error) => {
        // Handle the error
      });
  }
};
