import classes from "../SendPage/SendAndReceive.module.css";
export const ContactUs = ({ setcontatctUs, initialModalOptions }) => {
  return (
    <>
      <div className={classes.modalClose}>
        <h3>Contatct Us</h3>
        <svg
          onClick={() => setcontatctUs((prev) => !prev)}
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-square-rounded-x-filled"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="A800B7"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
            fill="currentColor"
            stroke-width="0"
          />
        </svg>
      </div>

      <p className={classes.questions}>Got Questions? Kindly Reach Out To Us</p>

      <div className={classes.checkBox}>
        <inpu />
        <a href="https://twitter.com/sentbyanon?s=21&t=ED2FI3rS00iCDnGeFSJo3g">
          <label>Twitter: @sentbyanon</label>
        </a>
      </div>
      <div className={classes.checkBox}>
        <inpu />
        <a href="https://instagram.com/sentbyanon?igshid=MzRlODBiNWFlZA==">
          <label>IG: @sentbyanon</label>
        </a>
      </div>

      <div className={classes.checkBox}>
        <inpu />

        <label>Email: Support@sentbyanon</label>
      </div>
    </>
  );
};
