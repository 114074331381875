import axios from "axios";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";

export const getAccountName = (
  receiverAccount,
  selectedBank,
  setaccountNameDisplay,
  accountNameDisplay,
  num10state,
  setnum10state
) => {
  const encryptData = (data) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const request = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      mode: CryptoJS.mode.ECB,
    }).toString();

    return request;
  };

  // Check if receiverAccount has a length of 10 and selectedBank is truthy
  if (receiverAccount.length === 10 && selectedBank) {
    axios
      .post(process.env.REACT_APP_NAME_ENQUIRY, {
        request: encryptData({
          accountNumber: receiverAccount,
          bankName: selectedBank,
        }),
      })
      .then((response) => {
        // Update the accountNameDisplay state with the received account name
        if (response.data.status && receiverAccount.length === 10) {
          setaccountNameDisplay(response.data.response.account_name);
          setnum10state("is 10");
        } else if (
          response.data.status === false &&
          response.data.response === "Name Enquiry failed: Invalid Account"
        ) {
          setaccountNameDisplay("Name Enquiry failed: Invalid Account");
          setnum10state("is 10");
        }
      })
      .catch((error) => {
        // Handle the error
      });
  }
};
