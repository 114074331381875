import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ContactUs } from "../Components/MinorComponents/ContactUs";
import classes from "../Components/Homepage/HomePage.module.css";
import { ThemeContext } from "../App";
import ReactSwitch from "react-switch";

const Nav = ({ toggleTheme }) => {
  const [currentDiv, setCurrentDiv] = useState(1);
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [contactUs, setcontactUs] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  const [dropdownUseSenttByAnon, setdropdownUseSenttByAnon] = useState(false);
  const [navDropDown, setnavDropDown] = useState(false);
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme === "dark" ? classes.dark : classes.light}>
      <nav
        className={`${classes.nav}  `}

        // ${
        //   theme === "dark" ? classes.dark : classes.light
        // }
      >
        <Link className={classes.links} to="/">
          {theme == "light" ? (
            <img
              alt="sentbyanon"
              src="https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            />
          ) : (
            <img
              alt="sentbyanon"
              src="https://res.cloudinary.com/da3avivzn/image/upload/v1695449650/sentbyanon/SentbyAnon_2_1_syu1lh.png"
            />
          )}
        </Link>

        <ul className={classes.ul}>
          <Link className={classes.link} to="/howtouse">
            <li>How To Use</li>
          </Link>
          <Link className={classes.link} to="/aboutus">
            <li>About Us</li>
          </Link>

          <Link className={classes.link} to="/team">
            <li>Team</li>
          </Link>
        </ul>
        <div className={classes.contactUs}>
          <button onClick={() => setcontactUs(!contactUs)}>Contact Us</button>

          {contactUs && (
            <>
              <div
                onClick={() => setcontactUs(!contactUs)}
                className={classes.overlay}
              ></div>
              <div className={classes.modal}>
                {initialModalOptions && (
                  <ContactUs setcontatctUs={setcontactUs} />
                )}
              </div>
            </>
          )}
        </div>
      </nav>
      <nav className={classes.newNav}>
        <Link className={classes.links} to="/">
          {theme == "light" ? (
            <img
              alt="sentbyanon"
              src="https://res.cloudinary.com/da3avivzn/image/upload/v1685818642/SentbyAnon_1_1_ia3do1.png"
            />
          ) : (
            <img
              alt="sentbyanon"
              src="https://res.cloudinary.com/da3avivzn/image/upload/v1695449650/sentbyanon/SentbyAnon_2_1_syu1lh.png"
            />
          )}
        </Link>

        {navDropDown && (
          <ul className={classes.newUl}>
            <svg
              className={classes.closeSvg}
              onClick={() => setnavDropDown(false)}
              xmlns="http://www.w3.org/2000/svg"
              // class="icon icon-tabler icon-tabler-category"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 4h6v6h-6z" />
              <path d="M14 4h6v6h-6z" />
              <path d="M4 14h6v6h-6z" />
              <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            </svg>
            <Link
              onClick={() => setnavDropDown(false)}
              className={classes.link}
              to="/"
            >
              <li>Home</li>
            </Link>

            <Link
              onClick={() => setnavDropDown(false)}
              className={classes.link}
              to="/howtouse"
            >
              <li>How To Use</li>
            </Link>
            <Link
              onClick={() => setnavDropDown(false)}
              className={classes.link}
              to="/aboutus"
            >
              <li>About Us</li>
            </Link>

            <Link
              onClick={() => setnavDropDown(false)}
              className={classes.link}
              to="/team"
            >
              <li>Team</li>
            </Link>
            {/* <div>
              <li onClick={() => setcontactUs(!contactUs)}>Contact Us</li>
            </div> */}
          </ul>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-category-2"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#A800B7"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          onClick={() => setnavDropDown(true)}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M14 4h6v6h-6z" />
          <path d="M4 14h6v6h-6z" />
          <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M7 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        </svg>
      </nav>
    </div>
  );
};

export default Nav;
