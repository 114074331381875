import axios from "axios";
// This Function gets list of Nigerian Banks
export const minGetAllBanks = (setbankList, setbankListFromApi) => {
  setbankList((prev) => !prev);

  axios
    .get(process.env.REACT_APP_LIST_OF_NIGERIAN_BANKS)
    .then((response) => {
      const listOfBanks = response.data.response;
      setbankListFromApi(listOfBanks);
    })
    .catch((error) => {});
};
