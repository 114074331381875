import React, { useState } from "react";
import classes from "./Team.module.css";
import { Link } from "react-router-dom";
import { ContactUs } from "../MinorComponents/ContactUs";
import CarouselPage from "../Carousel/Carousel";
import Nav from "../../NavigationMobile/NavigationMobile";
const Team = ({ data }) => {
  const [initialModalOptions, setinitialModalOptions] = useState(true);
  const [contactUs, setcontactUs] = useState(false);
  const [howToUseCarousel, sethowToUseCarousel] = useState(false);
  return (
    <section className={classes.section}>
      <Nav />
      {contactUs && (
        <>
          <div className={classes.overlay}></div>
          <div className={classes.modal}>
            {initialModalOptions && <ContactUs setcontatctUs={setcontactUs} />}
          </div>
        </>
      )}{" "}
      {howToUseCarousel && (
        <div>
          <div
            onClick={() => sethowToUseCarousel(false)}
            className={classes.overlay}
          ></div>

          <CarouselPage />
        </div>
      )}
      <main className={classes.main}>
        <h3>Meet The Team</h3>
        <div className={classes.teamData}>
          {data.map((item) => (
            <div className={classes.teamInfo}>
              <div className={classes.profileImgBox}>
                <img src={item.img} alt="" />
              </div>
              <h6>{item.name}</h6>
              <span>{item.role}</span>
            </div>
          ))}
        </div>
      </main>
      <div>
        <h5 className={classes.copywright}>
          © SentbyAnon 2023. All rights reserved.
        </h5>
      </div>
    </section>
  );
};

export default Team;
