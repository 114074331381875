import logo from "./logo.svg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TeamData from "./Components/Team/TeamData";
import "./App.css";
import { useContext, useState } from "react";
import SendAndReceive from "./Components/SendPage/SendAndReceive";
import MultiplePage from "./Components/MultiplePage/MultiplePage";
import HomePage from "./Components/Homepage/HomePage";
import Team from "./Components/Team/Team";
import HowToUsePage from "./Components/HowToUsePage/HowToUsePage";
import { AppStateProvider } from "./Components/AppStateContext";
import AboutUsPage from "./Components/AboutUsPage/AboutUsPage";
import PoundsPayment from "./Components/PoundsPayment/PoundsPayment";
import SuccessFulPage from "./Components/SuccesfulPages/SuccessfulPage";
import UnSuccessFulPage from "./Components/SuccesfulPages/UnsuccesfulPages";
import { StateProvider } from "./Components/TrasanctionRefContext";
import GetLink from "./Components/GetLink/GetLink";
import AnonLinkPayment from "./Components/AnonLinkPayment/AnonLinkPayment";
import Dashboard from "./Components/Dashboard/Dashbaord";
import { createContext } from "react";
import "../src/App.css";
import classes from "../src/App.module.css";
import ReactSwitch from "react-switch";
import Nav from "./NavigationMobile/NavigationMobile";
export const ThemeContext = createContext(null);
function App() {
  const [data, setdata] = useState(TeamData);
  const [theme, settheme] = useState("dark");
  const toggleTheme = () => {
    settheme((curr) => (curr === "light" ? "dark" : "light"));
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div
        className={`'App' ${theme === "dark" ? classes.dark : classes.light}`}
        id={theme}
      >
        <Router>
          <StateProvider>
            <AppStateProvider>
              <Routes>
                <Route
                  path="/"
                  element={<HomePage toggleTheme={toggleTheme} />}
                />
                <Route element={<Nav toggleTheme={toggleTheme} />} />
                <Route path="/sendreceive" element={<SendAndReceive />} />
                <Route
                  path="/sendreceive/multiple"
                  element={<MultiplePage />}
                />
                <Route path="/team" element={<Team data={data} />} />
                <Route path="/howtouse" element={<HowToUsePage />} />
                <Route path="/aboutus" element={<AboutUsPage />} />
                <Route path="/poundspayment" element={<PoundsPayment />} />
                {/* <Route path="/success/:token" element={<SuccessFulPage />} />
              <Route path="/failed/:token" element={<UnSuccessFulPage />} /> */}
                <Route path="/anonlink" element={<GetLink />} />
                <Route path="/:ref/:name" element={<AnonLinkPayment />} />
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </AppStateProvider>
          </StateProvider>
        </Router>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
