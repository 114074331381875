import React, { useState } from "react";
import classes from "../SendPage/SendAndReceive.module.css";
const SuccessfulTransactionImage = ({ transactionRef }) => {
  return (
    <>
      <img
        className={classes.succesfulImage}
        src="https://res.cloudinary.com/da3avivzn/image/upload/v1685694592/248_finance_Converted_1_kstqkw.png"
      />
      <h5 className={classes.successText}>
        Thank you for your recent transaction.
        <br />
        <br />
        Your transaction has been completed successfully. To track your
        transaction, please copy the transaction reference {transactionRef} and
        send it to us at fxsupport@sentbyanon.com
      </h5>
    </>
  );
};

export default SuccessfulTransactionImage;
